import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '@backoffice-frontend/shared/directives/common';
import {
  ListFacade,
  StandardizedLoadProfile
} from '@clean-code/backoffice/standardized-load-profile/domain';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { DataTableBaseComponent } from '@clean-code/shared/components/ui-data-table';
import { UiMatTableModule } from '@clean-code/shared/components/ui-mat-table';
import { PageContainerModule } from '@clean-code/shared/components/ui-page-container';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { StandardizedLoadProfileSearchComponent } from './standardized-load-profile-search/standardized-load-profile-search.component';
@Component({
  standalone: true,
  selector: 'backoffice-standardized-load-profile-list',
  templateUrl: './standardized-load-profile-list.component.html',
  styles: ['.icon { font-size:20px;}'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,

    TranslocoModule,
    FontAwesomeModule,
    NgxPermissionsModule,

    UiMatTableModule,
    ActionButtonModule,
    DirectivesModule,
    PageContainerModule,

    StandardizedLoadProfileSearchComponent,
  ],
})
export class StandardizedLoadProfileListComponent extends DataTableBaseComponent<StandardizedLoadProfile> {
  protected override navigateUrl = '/standardized-load-profiles';

  public faCheck = faCheck;

  constructor(protected service: ListFacade) {
    super(service);
  }

  public override add() {
    this.router.navigate(['/standardized-load-profile/new']);
  }
}
