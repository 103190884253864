@if (isLoading$ | async) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
@if (data$ | async; as data) {
  <table class="table-layout" *ngVar="config$ | async; let config">
    <thead>
      <tr>
        <th>{{ 'time-series-identifier.widgets.DATA_SOURCES' | transloco }}</th>
        <th
          [innerHtml]="
            hideClosingValueHeader()
              ? ''
              : ('time-series-identifier.widgets.CLOSING_PRICE' | transloco)
          "
        ></th>
        <th class="w-[14%]"></th>
        <th class="w-[4%]"></th>
        @switch (deltaType) {
          @case ('DAY') {
            <th class="whitespace-nowrap">∆ 1 T</th>
          }
          @case ('MONTH') {
            <th class="whitespace-nowrap">∆ 1 M</th>
          }
          @default {
            <th class="whitespace-nowrap">DELTATYPE MISSING!</th>
          }
        }
        <th class="whitespace-nowrap">∆ 1 J</th>
      </tr>
    </thead>
    <tbody *ngVar="editMode$ | async; let editMode">
      @for (item of data; track item; let i = $index) {
        <tr>
          <td
            class="cursor-pointer hover:underline"
            (click)="
              !editMode
                ? openDetails(item, config.dataSources[i])
                : openDetailsConfig(config.dataSources[i]?.settings)
            "
          >
            {{ item.shortName }}
            @if (!!item?.error?.message) {
              <fa-icon
                [icon]="faTriangle"
                class="text-red-600"
                global-tooltip="{{ item?.error?.message | transloco }}"
              ></fa-icon>
            }
            @if (editMode) {
              <fa-icon [icon]="faEdit"></fa-icon>
            }
          </td>
          <td>
            {{ item.value | number: '.2-2' }}
          </td>
          <td>{{ item.unit }}</td>
          <!-- Icon depending on Day or Month -->
          @if (item.deltaDay || item.deltaMonth) {
            <td class="delta">
              @switch (deltaType) {
                @case ('DAY') {
                  @switch (item.calculationType) {
                    @case ('RELATIVE') {
                      <fof-trend-icon
                        [deltaPercent]="item.deltaDay"
                        iconSize="lg"
                      ></fof-trend-icon>
                    }
                    @case ('ABSOLUTE') {
                      <fof-trend-icon
                        [deltaAbsolute]="item.deltaDay"
                        iconSize="lg"
                      ></fof-trend-icon>
                    }
                  }
                }
                @case ('MONTH') {
                  @switch (item.calculationType) {
                    @case ('RELATIVE') {
                      <fof-trend-icon
                        [deltaPercent]="item.deltaMonth"
                        iconSize="lg"
                      ></fof-trend-icon>
                    }
                    @case ('ABSOLUTE') {
                      <fof-trend-icon
                        [deltaAbsolute]="item.deltaMonth"
                        iconSize="lg"
                      ></fof-trend-icon>
                    }
                  }
                }
              }
            </td>
          }
          @if (!item.deltaDay && !item.deltaMonth) {
            <td>
              <fof-trend-icon [deltaPercent]="0" iconSize="lg"></fof-trend-icon>
            </td>
          }
          <!-- Delta Day or Month -->
          <td class="delta">
            @switch (deltaType) {
              @case ('DAY') {
                @switch (item.calculationType) {
                  @case ('RELATIVE') {
                    {{
                      !item.deltaDay ? '-' : (item.deltaDay | percent: '.2-2')
                    }}
                  }
                  @case ('ABSOLUTE') {
                    {{
                      !item.deltaDay ? '-' : (item.deltaDay | number: '.2-2')
                    }}
                  }
                }
              }
              @case ('MONTH') {
                @switch (item.calculationType) {
                  @case ('RELATIVE') {
                    {{
                      !item.deltaMonth
                        ? '-'
                        : (item.deltaMonth | percent: '.2-2')
                    }}
                  }
                  @case ('ABSOLUTE') {
                    {{
                      !item.deltaMonth
                        ? '-'
                        : (item.deltaMonth | number: '.2-2')
                    }}
                  }
                }
              }
            }
          </td>
          <!-- Delta Year -->
          @if (item.deltaYear) {
            <td class="delta">
              @switch (item.calculationType) {
                @case ('RELATIVE') {
                  {{ item.deltaYear | percent: '.2-2' }}
                }
                @case ('ABSOLUTE') {
                  {{ item.deltaYear | number: '.2-2' }}
                }
              }
            </td>
          }
          @if (!item.deltaYear) {
            <td>-</td>
          }
        </tr>
      }
    </tbody>
  </table>
} @else {
  <div class="no-chart-data flex flex-row justify-center items-center">
    <ng-container> {{ 'common.LOADING' | transloco }}</ng-container>
  </div>
}
