import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AreaDropdownComponent } from '@backoffice-frontend/shared/bo/components/ui-area-dropdown';
import { CompaniesDropdownComponent } from '@backoffice-frontend/shared/bo/components/ui-company-dropdown';
import { KamDropdownModule } from '@backoffice-frontend/shared/bo/components/ui-kam-dropdown';
import { MasterDataHelper } from '@backoffice-frontend/shared/bo/util-masterdata';
import { EnergySourceDropdownComponent } from '@clean-code/shared/bo/components/ui-energy-source-dropdown';
import { EnergySourceEnum } from '@clean-code/shared/bo/util-enum';
import { BaseFormChild } from '@clean-code/shared/components/ui-form-controls';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { TranslocoModule } from '@jsverse/transloco';
import { map } from 'rxjs/operators';
import {
  DateRangeInputForm,
  SharedComponentsUiDateRangeComponent,
} from 'shared/components/ui-date-range';
import { BaseFilterService } from '../base-filter.service';
import { BaseFilterCompanySegment } from '../models/filter-company-type';
@Component({
  standalone: true,
  selector: 'bo-widget-base-filter',
  templateUrl: './widget-base-filter.component.html',
  styleUrls: ['./widget-base-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WidgetBaseFilterComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WidgetBaseFilterComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatSelectModule,
    UtilDateModule,

    TranslocoModule,

    EnergySourceDropdownComponent,
    AreaDropdownComponent,
    CompaniesDropdownComponent,
    KamDropdownModule,
    WidgetBaseFilterComponent,
    SharedComponentsUiDateRangeComponent,
  ],
})
export class WidgetBaseFilterComponent extends BaseFormChild {
  @HostBinding('class') class = 'bo-widget-base-filter';

  private _showEnergySource: boolean;
  @Input()
  public set showEnergySource(value: boolean) {
    this._showEnergySource = value;
    if (!value) {
      this.formGroup.controls.energySource.disable();
    }
  }

  public get showEnergySource() {
    return this._showEnergySource;
  }

  locale: any = {
    separator: ' bis ',
    cancelLabel: 'Cancel',
    applyLabel: 'Anwenden',
    format: 'DD.MM.YYYY',
  };

  public override formGroup = new UntypedFormGroup({
    energySource: new UntypedFormControl(
      EnergySourceEnum.Power,
      Validators.required,
    ),
    marketAreaId: new UntypedFormControl(''),
    deliveryAreaId: new UntypedFormControl(''),
    kamIds: new UntypedFormControl(null),
    segmentId: new UntypedFormControl(''),
    years: new UntypedFormControl(''),
    companyIds: new UntypedFormControl(''),
    selectedRange: new DateRangeInputForm(null),
  });

  public segments$ = this.companyTypeService.get$().pipe(
    map((values: BaseFilterCompanySegment[]) =>
      values.map((value: BaseFilterCompanySegment) => {
        return {
          ...value,
          id: +value.id,
        };
      }),
    ),
  );

  public years = MasterDataHelper.getYears();

  public energySource$ = this.formGroup.controls.energySource.valueChanges;

  constructor(private companyTypeService: BaseFilterService) {
    super();
  }

  override writeValue(value: any) {
    if (
      value &&
      (!value?.selectedRange?.startDate || !value?.selectedRange?.endDate)
    ) {
      value.selectedRange = null; // HACK: without null is the today date the placeholder
    }

    super.writeValue(value);
    if (!this.formGroup.controls.energySource.value && !this.showEnergySource) {
      this.formGroup.controls.energySource.setValue(EnergySourceEnum.Power);
    }
  }
}
