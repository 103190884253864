import { Injectable } from '@angular/core';
import { TimeSeriesInput } from '@backoffice-frontend/time-series-identifier/api';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { MarketOverviewWidgetData } from '../market-overview-widget-content/market-overview-widget-content.models';

@Injectable({
  providedIn: 'root',
})
export class OverviewWidgetService {
  constructor(private graphqlService: GraphqlService) {}

  public marketOverviewWidgetData$(
    value: TimeSeriesInput
  ): Observable<MarketOverviewWidgetData> {
    const query = `
          query($identifier: TimeSeriesInput!){
            marketOverviewWidgetData(identifier: $identifier)
            {
              currentValue
              previousValue
              timeIdentifierName
              energySource
              category
              identifier
              identifierId
              unit
              isIntradayValue
              valueTimeStamp
              frontOfficeName
              longName
            }
          }`;

    return this.graphqlService.query<MarketOverviewWidgetData>(
      query,
      {
        identifier: {
          identifierId: value.identifier.identifierId,
          adjustment: value.identifier.adjustment,
          //sortOrder: value.sortOrder
        },
      },
      null
    );
  }
}
