import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  inject,
} from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  AuthInjectorToken,
  AuthStore,
} from '@clean-code/shared/auth/util-auth';
import { AzureConfig } from './model/azure-config';
import { AzureAuthService } from './services/azure-auth.service';

export function loggerCallback(_logLevel: LogLevel, message: string) {
  console.debug(message);
}

export function MSALInstanceFactory(
  config: AzureConfig,
): IPublicClientApplication {
  return new PublicClientApplication({
    auth: config.auth,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(
  config: AzureConfig,
): MsalInterceptorConfiguration {
  const protectedResourceMap = config.protectedResourceMap;
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/login',
  };
}

export function authInitializerFactory() {
  const authStore = inject(AuthStore);
  return async () => {
    authStore.loadFromStorage();
  };
}

export const AUTH_CONFIG = new InjectionToken<AzureConfig>('');

@NgModule({
  imports: [CommonModule],
})
// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export class AzureAuthModule {
  static forRoot(config?: AzureConfig): ModuleWithProviders<AzureAuthModule> {
    return {
      ngModule: AzureAuthModule,
      providers: [
        { provide: AUTH_CONFIG, useValue: config },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [AUTH_CONFIG],
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [AUTH_CONFIG],
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: AuthInjectorToken, useClass: AzureAuthService },
        {
          provide: APP_INITIALIZER,
          useFactory: authInitializerFactory,
          multi: true,
        },
      ],
    };
  }
}
