import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDashboardWidgetDetailDto } from '@clean-code/shared/components/ui-dashboard';
import { DashboardWidgetFacade } from '@clean-code/shared/dashboard/widget/api-widget';
import { WidgetStore } from '@clean-code/shared/dashboard/widget/domain';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { DashboardWidgetReferenceComponent } from '../widget-reference/widget-reference.component';

@Component({
  standalone: true,
  templateUrl: './widget-reference-dialog.component.html',
  styleUrls: ['./widget-reference-dialog.component.scss'],
  imports: [FontAwesomeModule, DashboardWidgetReferenceComponent],
  providers: [WidgetStore, DashboardWidgetFacade],
})
export class DashboardWidgetReferenceDialogComponent {
  public faXmark = faXmark;

  constructor(
    public dialogRef: MatDialogRef<DashboardWidgetReferenceDialogComponent>
  ) {}

  public onSave(data: {
    operation: string;
    data: IDashboardWidgetDetailDto;
  }): void {
    // if (data && data.operation !== 'update') {
    //   this.router.navigate(['/dashboard', data.data.id]);
    // }
    // refresh dashboard widgets
    this.dialogRef.close(data?.data);
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
