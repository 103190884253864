import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { indicate } from '@clean-code/shared/common';
import {
  IDashboardWidgetDetailDto,
  IDashboardWidgetDto,
  IDashboardWidgetReferenceDto,
} from '@clean-code/shared/components/ui-dashboard';
import { LoadingIndicatorComponent } from '@clean-code/shared/components/ui-progress-bar';
import { DashboardWidgetFacade } from '@clean-code/shared/dashboard/widget/domain';
import { ToastService } from '@clean-code/shared/util/util-toast';
import { TranslocoModule } from '@jsverse/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { DashboardWidgetPreviewComponent } from '../widget-preview/widget-preview.component';

@Component({
  standalone: true,
  selector: 'dashboard-widget-reference',
  templateUrl: './widget-reference.component.html',
  styleUrls: ['./widget-reference.component.scss'],
  imports: [
    CommonModule,

    TranslocoModule,

    DashboardWidgetPreviewComponent,
    LoadingIndicatorComponent,
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetReferenceComponent {
  @ViewChild('form', { static: false }) public form: UntypedFormControl;

  @Input() public multi = false;
  @Output() public callback = new EventEmitter<{
    operation: string;
    data: IDashboardWidgetDetailDto;
  }>();

  public widgets$: Observable<IDashboardWidgetDto[]>;
  public isLoading$ = new BehaviorSubject<boolean>(false);
  private selectedWidgets: IDashboardWidgetDto[] = [];

  constructor(
    private dashboardWidgetFacade: DashboardWidgetFacade,
    @Inject(MAT_DIALOG_DATA) public widget: IDashboardWidgetDetailDto,
    public dialogRef: MatDialogRef<DashboardWidgetReferenceComponent>,
    private toastService: ToastService,
  ) {
    this.widgets$ = this.dashboardWidgetFacade
      .getAssignable$(this.widget.dashboardId)
      .pipe(indicate(this.isLoading$));
  }

  public isSelected(widget: IDashboardWidgetDto) {
    return this.selectedWidgets.some((d) => d.id === widget.id);
  }

  public toggleSelection(widget: IDashboardWidgetDto) {
    if (this.multi) {
      if (this.isSelected(widget)) {
        const index = this.selectedWidgets.findIndex((d) => d.id === widget.id);
        if (index > -1) {
          this.selectedWidgets.splice(index, 1);
        }
      } else {
        this.selectedWidgets.push(widget);
      }
    } else {
      this.selectedWidgets.splice(0, this.selectedWidgets.length);
      this.selectedWidgets.push(widget);

      this.save();
    }
  }

  public save() {
    // TODO: one request to reference multiple widgets
    const results: IDashboardWidgetDetailDto[] = [];

    this.selectedWidgets.forEach((w) => {
      this.dashboardWidgetFacade
        .reference$({
          dashboardId: this.widget.dashboardId,
          id: w.id,
        } as IDashboardWidgetReferenceDto)
        .subscribe((result: IDashboardWidgetDetailDto) => {
          if (result) {
            this.toastService.showSuccess(
              'DASHBOARDWIDGET.MESSAGES.REFERENCE.SUCCESS',
            );

            results.push(result);

            if (results.length === this.selectedWidgets.length) {
              this.callback.next({
                operation: 'reference',
                data: results[0],
              });
            }
          }
        });
    });
  }
}
