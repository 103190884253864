import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InputData } from '@backoffice-frontend/time-series-identifier/domain';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { MarketDetailsComponent } from '@clean-code/time-series-identifier/ui-details';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons';
import { BehaviorSubject } from 'rxjs';

@Component({
  standalone: true,
  templateUrl: './table-widget-details-dialog.component.html',
  styleUrls: ['./table-widget-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatDialogModule,
    FontAwesomeModule,

    ProgressBarComponent,
    MarketDetailsComponent,
  ],
})
export class TableDetailsDialogComponent {
  public faXmarkLarge = faXmarkLarge;
  public isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<TableDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dataSource: InputData;
      name: string;
      hideClosingValueHeader: boolean;
    }
  ) {}
}
