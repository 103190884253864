import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MultiSelectAutocompleteModule } from '@clean-code/shared/components/ui-multiselect-autocomplete';
import { KamDropdownComponent } from './kam-dropdown.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MultiSelectAutocompleteModule],
  declarations: [KamDropdownComponent],
  exports: [KamDropdownComponent],
})
export class KamDropdownModule {}
