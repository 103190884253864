<page-container>
  @if (isLoading$ | async) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }

  <ng-container title>
    {{ 'monitoring.HEADER' | transloco }}
  </ng-container>

  <cc-table-preview width="30%">
    <cc-table-container [isLoading]="isLoading$ | async">
      <!-- polling settings -->
      <div table-buttons>
        @if (settings$ | async; as setting) {
          <span
            class="mt-[5px]"
            [innerHtml]="
              'monitoring.POLLING_INTERVAL'
                | transloco: { interval: setting.pollingInterval }
                | safeHtml
            "
          ></span>
          <cc-action-button
            class="!mx-5"
            displayText="{{
              pollingToggle === true
                ? 'monitoring.STOP_POLLING'
                : 'monitoring.START_POLLING'
            }}"
            (onClick)="togglePolling()"
          ></cc-action-button>
        }
      </div>

      <!-- polling settings end -->

      <!-- info block -->
      @if (data$ | async; as data) {
        <div>
          <div class="flex flex-row justify-between w-9/12">
            <h2 class="mx-2">{{ data.name }}</h2>
            <p class="mx-2">
              <status-symbol
                class="mr-2"
                [status]="data.status"
              ></status-symbol>
              <span>
                {{
                  'monitoring.status.' + data.status.toLocaleUpperCase()
                    | transloco
                }}
              </span>
            </p>
            <p class="mx-2 text-sm">
              <span class="mr-2 italic">{{
                'monitoring.ON_STATE_FROM' | transloco
              }}</span>
              <span>{{ data.onStateFrom | date: 'medium' }}</span>
            </p>
            <p class="mx-2 text-sm">
              <span class="mr-2 italic">{{
                'monitoring.LAST_EXECUTION' | transloco
              }}</span>
              <span>{{ data.lastExecuted | date: 'medium' }}</span>
            </p>
          </div>
        </div>
      }
      <!-- end info block -->

      <!-- table -->
      <div class="h-[calc(100%-23px)] overflow-auto">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          class="w-full h-full"
        >
          <ng-container matColumnDef="operations">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              @if (!isServiceExecuting(element.id)) {
                <cc-action-button
                  [icon]="faPlay"
                  displayText="monitoring.EXECUTE"
                  (onClick)="executeService(element, $event)"
                ></cc-action-button>
              }
              @if (isServiceExecuting(element.id)) {
                <cc-action-button
                  [disabled]="true"
                  [icon]="faSpinner"
                  [spin]="true"
                  displayText="monitoring.EXECUTING"
                ></cc-action-button>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'monitoring.NAME' | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.name.includes('monitoring.')
                  ? (element.name | transloco)
                  : element.name
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'monitoring.HEALTH' | transloco }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              title="{{ element.exception }}"
            >
              <span
                ><status-symbol
                  class="mr-2"
                  [status]="element.status"
                ></status-symbol>
                {{
                  'monitoring.status.' + element.status.toLocaleUpperCase()
                    | transloco
                }}</span
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'monitoring.DESCRIPTION' | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element?.description?.includes('monitoring.')
                  ? (element.description | transloco)
                  : element.description
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'monitoring.DETAILS' | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.details | germanDateFormatCustom | dateTimeCommaCustom
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'monitoring.DURATION' | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.duration }}</td>
          </ng-container>

          <ng-container matColumnDef="tags">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'monitoring.TAGS' | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.tags }}</td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            class="entity-row"
            *matRowDef="let row; columns: displayedColumns"
            (click)="viewHistory(row)"
            [ngClass]="{ selected: selectedRow === row.id }"
          ></tr>
        </table>
      </div>
      <!-- end table -->
    </cc-table-container>
  </cc-table-preview>
</page-container>
