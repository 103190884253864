import { Component, inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { DashboardReferenceComponent } from '../dashboard-reference/dashboard-reference.component';

@Component({
  standalone: true,
  templateUrl: './dashboard-reference-dialog.component.html',
  imports: [FontAwesomeModule, MatDialogModule, TranslocoModule, DashboardReferenceComponent, ActionButtonModule],
})
export class DashboardReferenceDialogComponent {
  public dialogRef = inject(MatDialogRef<DashboardReferenceDialogComponent>);
  faTimes = faTimes;

  public onSave(): void {
    this.dialogRef.close();
  }

}
