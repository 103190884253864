<cc-dashboard-page-container>
  <ng-container title>
    {{ 'load-profile.TITLE_PLURAL' | transloco }}
  </ng-container>

  <cc-table-preview page-content>
    <cc-table-container [isLoading]="isLoading$ | async">
      <ng-container table-filter>
        <backoffice-loadprofile-search
          [searchTermProperties]="['name']"
        ></backoffice-loadprofile-search>
      </ng-container>
      <div table-buttons class="flex flex-col sm:flex-row">
        <cc-add-action-button (onClick)="add()"></cc-add-action-button>
      </div>

      <bo-mat-table
        matSort
        [data]="paging$ | async"
        [showPaginator]="false"
        (pageChange)="setPage($event)"
        (matSortChange)="onSort($event)"
        >
        <ng-container matColumnDef="isImported">
          <th mat-header-cell *matHeaderCellDef>
            <span class="text-left">{{
              'load-profile.upload.IS_IMPORTED' | transloco
            }}</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <fa-icon
              class="icon"
              [ngClass]="{
                'text-secondary-color-800': !element.isImported,
                'text-primary-color-600': element.isImported
              }"
              [ngStyle]="{
                display: !element.isImported ? 'none' : ''
              }"
              [icon]="faCheck"
              matTooltip="{{ 'load-profile.upload.IS_IMPORTED' | transloco }}"
            ></fa-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            <span class="text-left">{{
              'load-profile.fields.NAME' | transloco
            }}</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="company.name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            <span class="text-left">{{ 'company.NAME' | transloco }}</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.company.name }}</td>
        </ng-container>

        <ng-container matColumnDef="keyAccountManagers">
          <th mat-header-cell *matHeaderCellDef>
            <span class="text-left">{{
              'load-profile.fields.KAM' | transloco
            }}</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.keyAccountManagers?.join('; ') }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>
            <span class="text-left">{{ 'common.CREATED_BY' | transloco }}</span>
          </th>
          <td mat-cell *matCellDef="let element">
            @if (element.createdByDec) {
              <div>
                {{ element.createdByDec.fullName }}
              </div>
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            <span class="text-left">{{
              'common.CREATED_DATE' | transloco
            }}</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdDate | date : 'shortDate' }}
          </td>
        </ng-container>
      </bo-mat-table>
    </cc-table-container>
  </cc-table-preview>
</cc-dashboard-page-container>
