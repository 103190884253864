import { Route } from '@angular/router';
import { limitReportGasDetails } from '@backoffice-frontend/limit-report/ui-gas';

export const routesLimitReportGas: Route = {
  path: '',
  children: [
    {
      path: '',
      loadChildren: () =>
        import('@backoffice-frontend/limit-report/ui-gas').then(
          (mod) => mod.LimitReportGasListModule
        ),
    },
    limitReportGasDetails,
  ],
};
