import { inject } from '@angular/core';
import { WidgetTypeProvider } from '@clean-code/shared/components/ui-dashboard';
import { TableWidgetConfigComponent } from './table-widget-config/table-widget-config.component';
import { TableWidgetComponent } from './table-widget/table-widget.component';

export enum WidgetTypes {
  TimeSeriesTableWidgetType = 'TimeSeriesTableWidget',
}

export function addTimeSeriesIdentifierTableWidget() {
  const widgetTypes = inject(WidgetTypeProvider);
  widgetTypes.add(
    WidgetTypes.TimeSeriesTableWidgetType,
    TableWidgetConfigComponent,
    TableWidgetComponent,
    ['backoffice', 'frontoffice', 'frontoffice-int']
  );
}
