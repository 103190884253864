<ng-container *rxLet="state$; let state">
  <button
    class="notification-center mx-2 text-layout-sidenav-text dark:text-layout-dark-sidenav-text relative"
    mat-ripple
    mat-button
    [matMenuTriggerFor]="notificationCenter"
  >
    @if (state.unreadCount === 0) {
      <fa-icon [icon]="faBell" size="2x"></fa-icon>
    }
    @if (state.unreadCount > 0) {
      <fa-icon [icon]="faBellOn" size="2x"></fa-icon>
    }
    @if (state.unreadCount > 0) {
      <span class="unread-count">{{ state.unreadCount }}</span>
    }
    @if (state.connectionState !== connectedState) {
      <span class="absolute text-red-500 top-[-6px] right-0">X</span>
    }
  </button>
  <mat-menu #notificationCenter="matMenu" class="notification-center-menu">
    @if (state.connectionState === connectedState) {
      <div
        class="flex flex-col p-1 min-w-[200px] max-w-[500px] origin-top-right divide-y focus:outline-none text-layout-sidenav-text dark:text-layout-dark-sidenav-text"
      >
        <div class="py-1" role="none">
          <div class="text-center">
            {{ 'notification.PLURAL' | transloco }}
          </div>
          <!-- <div class="extra">
            {{
            'notification.topnav.unread'
            | transloco: { unreadCount: unreadCount }
            }}.
          </div> -->
        </div>
        <div class="py-1 px-2" role="none">
          @if (state.messages.length === 0) {
            <div class="text-center">
              {{ 'notification.EMPTY' | transloco }}
            </div>
          }
          <ul role="list" class="divide-y divide-gray-100">
            @for (item of state.messages; track item.id) {
              <li class="flex justify-between gap-x-6 py-5">
                <notifications-notification-host
                  [notification]="item"
                ></notifications-notification-host>
              </li>
            }
          </ul>
        </div>
        <div
          class="flex flex-row w-full self-center justify-center text-center cursor-pointer text-primary-color"
          [ngClass]="{ hidden: state.unreadCount === 0 }"
          (click)="setAllRead($event)"
        >
          {{ 'notification.MARKALLREAD' | transloco }}
        </div>
      </div>
    } @else {
      <div class="disconnected">
        {{ 'notification.DISCONNECTED' | transloco }}
      </div>
    }
    <ng-template #disconnected
      ><div class="disconnected">
        {{ 'notification.DISCONNECTED' | transloco }}
      </div></ng-template
    >
  </mat-menu>
</ng-container>
