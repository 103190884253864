import { AbstractControl } from '@angular/forms';

// conditionalValidator(() => this.myForm.get('myTextField').value.includes('Illuminati'),
// Validators.compose([
//   Validators.required,
//   Validators.pattern(.*mason.*)
// ])

/** @type
 * generic conditional validator
 * @param  {any} predicate
 * @param  {any} validator
 */
export function conditionalValidator(predicate: any, validator: any) {
  return (formControl: AbstractControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return validator(formControl);
    }
    return null;
  };
}
