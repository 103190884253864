import { CommonModule, KeyValue } from '@angular/common';
import {
  Component,
  HostBinding,
  Injector,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import {
  TimeSeriesFacade,
  WidgetTypeConfig,
} from '@backoffice-frontend/time-series-identifier/domain';
import { indicate } from '@clean-code/shared/common';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { BaseChartWidget } from '@clean-code/shared/components/ui-dashboard';
import { DevExtremeLineChartComponent } from '@clean-code/shared/components/ui-devextreme-charts';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faMagnifyingGlassChart } from '@fortawesome/pro-light-svg-icons';
import { TitleOptions } from 'chart.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, filter, map, switchMap, tap } from 'rxjs';
import { ChartDialogComponent } from '../chart-dialog/chart-dialog.component';
import { chartData$ } from '../helper/chart.helper';

@Component({
  standalone: true,
  selector: 'chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,

    MatTabsModule,

    FontAwesomeModule,

    DevExtremeLineChartComponent,
    ProgressBarComponent,
  ],
})
export class ChartWidgetComponent extends BaseChartWidget<WidgetTypeConfig> {
  @HostBinding('class') class = 'chart-widget';

  public isLoading$ = new BehaviorSubject<boolean>(false);
  public errors = new Array<KeyValue<string, string>>();
  public info: string;
  public infoDate: Date;
  public faExpand = faMagnifyingGlassChart;

  @Input()
  public legend = false;

  @ViewChild(DevExtremeLineChartComponent)
  chart: DevExtremeLineChartComponent;

  @ViewChild(MatTabGroup)
  matTabGroup: MatTabGroup;

  public yAxisTitle$ = new BehaviorSubject<TitleOptions>(undefined);
  public data$: Observable<any>;
  private confirmService: ConfirmService;
  private deviceService: DeviceDetectorService;

  public constructor(injector: Injector) {
    super();

    const timeSeriesFacade = injector.get(TimeSeriesFacade);
    (this.deviceService = injector.get(DeviceDetectorService)),
      (this.confirmService = injector.get(ConfirmService));

    this.data$ = this.configObservable.pipe(
      filter((config: WidgetTypeConfig) => !!config),
      switchMap((config: WidgetTypeConfig) =>
        chartData$(
          timeSeriesFacade,
          config.dataSources.map((x) => x.settings.identifier),
          config.settings.timeFrame
        ).pipe(indicate(this.isLoading$))
      ),
      tap((values: any) => {
        this.errors = values.errors;
        this.infoDate = values.infoDate;
        this.info = values.info;
        this.yAxisTitle$.next(values.yAxisTitle);
      }),
      map((values: any) => values.array)
    );
  }

  expandChart() {
    this.confirmService.open(
      '',
      '',
      ChartDialogComponent,
      {
        widget: this.widget,
        timeFrame: this.config$.value.settings.timeFrame,
        dataSource:
          this.config$.value.dataSources[this.matTabGroup.selectedIndex],
      },
      {
        maxWidth: '100vw',
        maxHeight: '100vh',
        width: this.deviceService.isMobile() ? '100vw' : '90vw',
        height: this.deviceService.isMobile() ? '100vh' : 'unset', //90vh
      }
    );
  }
}
