<ng-container [formGroup]="formGroup">
  <div class="flex flex-row space-x-2">
    <mat-form-field>
      <mat-label>{{
        'ui-formula-methods.AVERAGE_PERIODE' | transloco
      }}</mat-label>
      <input matInput formControlName="averagePeriod" />
    </mat-form-field>
  </div>
</ng-container>
