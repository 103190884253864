import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { DevExtremeThemeService } from '@backoffice-frontend/shared/devextreme';
import { TailwindThemeService } from '@backoffice-frontend/shared/ui-tailwind';
import { LanguageService } from '@backoffice-frontend/shared/util-transloco';
import { SideNavService } from '@clean-code/shared/templates/ui-tailwind-full-width';
import { ENV_TOKEN, EnvConfig } from '@clean-code/shared/util-config';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLanguage } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowRightRotate,
  faCircleHalfStroke,
} from '@fortawesome/pro-solid-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { map, of } from 'rxjs';
import { animateFade, animateSlide } from '../animations/animations';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    FontAwesomeModule,
    TranslocoModule,
    // UserMenuComponent,
    SidebarComponent,
    UserMenuComponent,
  ],
  animations: [animateFade, animateSlide],
})
export class SideNavComponent {
  public readonly env = inject<EnvConfig>(ENV_TOKEN);
  public readonly service = inject(SideNavService);
  public readonly languageService = inject(LanguageService);
  private readonly tailwindThemeService = inject(TailwindThemeService);
  public faLanguage = faLanguage;
  public faCircleHalfStroke = faCircleHalfStroke;
  public faArrowRightRotate = faArrowRightRotate;

  public year: number = new Date().getFullYear();
  toggleControl = new FormControl(this.tailwindThemeService.isDarkMode);

  public langs$ = of(this.languageService.configuredLanguages).pipe(
    map(
      (
        l:
          | string[]
          | {
              id: string;
              label: string;
            }[],
      ) =>
        l.map(
          (x) =>
            x as {
              id: string;
              label: string;
            },
        ),
    ),
  );

  constructor() {
    const devExtremeThemeService = inject(DevExtremeThemeService);

    this.toggleControl.valueChanges.subscribe((darkMode) => {
      this.tailwindThemeService.changeTheme(darkMode);
      devExtremeThemeService.changeTheme(darkMode);
    });
  }

  setLanguage(id: string | MatButtonToggleChange) {
    let lang: string;
    if (typeof id === 'string' || id instanceof String) {
      lang = id as string;
    } else {
      lang = id.value;
    }

    if (this.languageService.currentLocale !== lang) {
      this.languageService.setLanguage(lang);
    }
  }

  // refreshVersion() {
  //   this.updateService.removeOldCache();
  // }
}
