import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TimeSeriesInput } from '@backoffice-frontend/time-series-identifier/domain';
import { TimeSeriesDynamicSelectionGroupComponent } from '@backoffice-frontend/time-series-identifier/ui-time-series-dynamic-selection';
import { ObjectHelper } from '@clean-code/shared/common';
import { TranslocoModule } from '@jsverse/transloco';
import { WidgetHelper } from '../helper/widget.helper';

@Component({
  standalone: true,
  templateUrl: './table-widget-details-edit-dialog.component.html',
  styleUrls: ['./table-widget-details-edit-dialog.component.scss'],
  imports: [
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatChipsModule,
    MatButtonToggleModule,

    TranslocoModule,

    TimeSeriesDynamicSelectionGroupComponent,
  ],
})
export class TableWidgetDetailsEditDialogComponent {
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('periodeInput') periodeInput: ElementRef<HTMLInputElement>;
  periodeCtrl = new UntypedFormControl();

  public formGroup = WidgetHelper.createDataSourceForm();

  public smaPeriode$ = this.periodeArray.valueChanges;

  public editableData: TimeSeriesInput;

  constructor(
    public dialogRef: MatDialogRef<TimeSeriesInput>,
    @Inject(MAT_DIALOG_DATA) public data: TimeSeriesInput
  ) {
    if (data) {
      this.editableData = ObjectHelper.cloneObject(data);

      this.formGroup.patchValue(this.editableData);

      if (this.editableData?.sma) {
        this.periodeArray.setValue(this.editableData.sma.periode);
      }
    }
  }

  public get settings(): UntypedFormGroup {
    return this.formGroup as UntypedFormGroup;
  }

  public get smaFormGroup(): UntypedFormGroup {
    return this.settings.controls.sma as UntypedFormGroup;
  }

  public get periodeArray(): UntypedFormControl {
    return this.smaFormGroup.controls.periode as UntypedFormControl;
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;
    const array = this.periodeArray.value as Array<number>;

    // Add
    if ((value || '').trim()) {
      array.push(+value.trim());
      this.periodeArray.setValue(array);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.periodeCtrl.setValue(null);
  }

  remove(value: number): void {
    const array = this.periodeArray.value as Array<number>;

    const index = array.indexOf(value, 0);
    if (index > -1) {
      array.splice(index, 1);
      this.periodeArray.setValue(array);
    }
  }

  public setDataAndClose() {
    //write old values which were not changed on new data (sortOrder, includeGaps, etc.)
    const newData = this.formGroup.value;

    this.dialogRef.close({ value: newData, action: 'add' });
  }
}
