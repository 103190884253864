
import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { WidgetBaseFilterComponent } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { PatchFormGroupValuesDirective } from '@clean-code/shared/directives/ui-form';
import { MarketHknDistributionWidgetTypeConfig } from '../market-hkn-distribution';
@Component({
  standalone: true,
  selector: 'dashboard-market-hkn-distribution-config',
  templateUrl: './market-hkn-distribution-config.component.html',
  styleUrls: ['./market-hkn-distribution-config.component.css'],
  imports: [
    ReactiveFormsModule,
    PatchFormGroupValuesDirective,
    WidgetBaseFilterComponent
],
})
export class MarketHknDistributionConfigComponent extends BaseWidgetTypeConfigComponent<MarketHknDistributionWidgetTypeConfig> {
  override form = new UntypedFormGroup({
    filter: new UntypedFormControl(null, [Validators.required]),
  });

  public close() {
    //TODO: dashboard state
    // this.callback.next();
  }
}
