import { inject, Injectable } from '@angular/core';
import { cache, ID, IEntityPositionDto } from '@clean-code/shared/common';
import {
  IDashboardWidgetCorrelationsUpdateInput,
  IDashboardWidgetDetailDto,
} from '@clean-code/shared/components/ui-dashboard';
import { Observable } from 'rxjs';
import { DashboardStore } from '../+state/dashboard-signal.store';
import { DashboardDataService } from '../infrastructure/dashboard-data.service';
import {
  IDashboardDetailDto,
  IDashboardListDto,
  IDashboardPanelPreviewDto,
  IDashboardReferenceDto,
} from '../models/dashboard.models';

@Injectable({ providedIn: 'root' })
export class DashboardFacade {
  readonly store = inject(DashboardStore);

  public activeDasbhoardId$ = this.store.active;
  public allowedEdit$ = this.store.allowedEdit;
  public editMode = this.store.editMode;
  public isLoading = this.store.isLoading;
  public active = this.store.active;
  public widgets = this.store.mappedWidgets;
  public hasUserPermission = this.store.hasUserPermission;

  constructor(private service: DashboardDataService) {}

  add(input: IDashboardListDto) {
    return this.store.add(input);
  }

  reference$(input: IDashboardReferenceDto) {
    return this.service.reference$(input);
  }

  getAssigned$(): Observable<IDashboardListDto[]> {
    return this.service.getAssigned$();
  }

  getAssignable$(): Observable<IDashboardPanelPreviewDto[]> {
    return this.service.getAssignable$().pipe(cache());
  }

  getByIdWithTranslations$(id: number): Observable<IDashboardPanelPreviewDto> {
    return this.service.getByIdWithTranslations$(id);
  }

  getById$(id: ID) {
    return this.store.getById(id);
  }

  update$(dashboard: IDashboardDetailDto) {
    this.store.update(dashboard);
  }

  addWidget(inputWidget: IDashboardWidgetDetailDto) {
    this.store.addWidget(inputWidget);
  }

  removeWidget(inputWidget: IDashboardWidgetDetailDto) {
    this.store.removeWidget(inputWidget);
  }

  toggleEdit() {
    const editMode = !this.store.editMode();
    this.store.updateEditMode(editMode);
  }

  updateActive(dashboard: IDashboardDetailDto) {
    this.store.updateActive(dashboard);
  }

  updateWidgetPosition(id: ID, widget: IEntityPositionDto) {
    this.store.updateWidgetPosition(id, widget);
  }

  updateAllCorrelations$(input: IDashboardWidgetCorrelationsUpdateInput) {
    return this.service.updateAllCorrelations$(input);
  }

  addReference(d: IDashboardPanelPreviewDto) {
    this.store.addReference(d);
  }
}
