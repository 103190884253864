import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import { ChartJsLibModule } from '@backoffice-frontend/shared/components/ui-chart-js';
import { scopeLoader } from '@backoffice-frontend/shared/util-transloco';
import { fromResize } from '@clean-code/shared/common';
import {
  LineChartDataModel,
  LineChartModel,
  MultiChartModel,
  MultiPanelChartComponent,
} from '@clean-code/shared/components/ui-devextreme-charts';
import { SpreadCollection } from '@frontoffice/spread/domain';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { TitleOptions } from 'chart.js';
import dayjs from 'dayjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinct, map, takeUntil } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'spread-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'spread-chart',
        alias: 'spread-chart',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        ),
      },
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,

    TranslocoModule,

    ChartJsLibModule,
    MultiPanelChartComponent,
  ],
})
export class SpreadChartComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'spread-chart';

  public formGroupSpread = new UntypedFormGroup({
    showAvg: new UntypedFormControl(false),
    showTimeSeries: new UntypedFormControl(true),
  });

  public showSpread$ = this.formGroupSpread.valueChanges.pipe(
    map((value: { showAvg: boolean; showTimeSeries: boolean }) => {
      return {
        showBottomPane: value.showAvg,
        showTopPane: value.showTimeSeries,
      };
    })
  );

  public formGroupPrice = new UntypedFormGroup({
    showAvg: new UntypedFormControl(false),
    showTimeSeries: new UntypedFormControl(true),
  });

  public showPrice$ = this.formGroupPrice.valueChanges.pipe(
    map((value: { showAvg: boolean; showTimeSeries: boolean }) => {
      return {
        showBottomPane: value.showAvg,
        showTopPane: value.showTimeSeries,
      };
    })
  );

  public isLoading$ = new BehaviorSubject<boolean>(false);
  public filter$ = new Subject<string[]>();
  public timeFrame$ = new BehaviorSubject<TimeFrame>('M_3');
  public yAxisLabel$ = new BehaviorSubject<TitleOptions>(null);
  public aggregationInterval$ = this.timeFrame$.pipe(
    map((value: TimeFrame) => {
      if (value === 'D_10') {
        return 'day';
      } else {
        return 'month';
      }
    })
  );
  private data$ = new Subject<SpreadCollection>();
  @Input()
  set data(value: SpreadCollection) {
    this.data$.next(value);
  }

  @Input()
  public set timeFrame(value: TimeFrame) {
    this.timeFrame$.next(value);
  }

  @Input()
  public isWidget = false;

  public spread$ = this.data$.pipe(
    // tap((_) => {
    //   const scale = this.scale$.value;

    //   if (this.timeFrame === 'D_10') {
    //     scale['daily-x-axis'].time.unit = 'day';
    //   } else {
    //     scale['daily-x-axis'].time.unit = 'month';
    //   }

    //   if (
    //     TimeFrameUtilService.isStrictTimeFrame(this.timeFrame) ||
    //     (<IndividualTimeFrame>this.timeFrame).end > dayjs()
    //   ) {
    //     scale['daily-x-axis'].max = this.getDailyXAxisMaxValue();
    //   } else {
    //     scale['daily-x-axis'].max = undefined;
    //   }

    //   this.scale$.next(scale);
    // }),
    map((spreadCollection: SpreadCollection) => {
      const array = new Array<any>();

      // if (!this.yAxisLabel$.value) {
      //   const scale = {
      //     ...this.yAxisLabel$.value,
      //     display: true,
      //     text: spreadCollection.unit,
      //   };
      //   this.yAxisLabel$.next(scale);
      // }

      const multiChart: Partial<MultiChartModel> = {};

      multiChart.lineChartData = spreadCollection.spreads.map(
        (values: { key: string; value: { key: Date; value: number }[] }) => {
          const data = values.value.map(
            (v: { key: Date; value: number }) =>
              ({
                date: new Date(v.key),
                value: v.value,
              } as LineChartDataModel)
          );

          return {
            label: values.key,
            data: data,
            type: 'line',
            yAxisTitle: spreadCollection.unit,
            pane: 'topPane',

            // axis: 'y',
          } as Partial<LineChartModel>;
        }
      );

      multiChart.barChartData = spreadCollection.spreads.map(
        (values: { key: string; value: { key: Date; value: number }[] }) => {
          const data = values.value.map(
            (v: { key: Date; value: number }) =>
              ({
                date: new Date(v.key),
                value: v.value,
              } as LineChartDataModel)
          );

          return {
            label: values.key,
            data: data,
            type: 'bar',
            yAxisTitle: spreadCollection.unit,
            axis: 'bar',
            pane: 'bottomPane',
            aggregate: {
              method: 'avg',
            },

            // opacity: 0.3,
          } as Partial<LineChartModel>;
        }
      );

      return multiChart;
    })
  );

  public avg$ = this.data$.pipe(
    map((spreadCollection: SpreadCollection) => {
      const multiChart: Partial<MultiChartModel> = {};

      multiChart.lineChartData = spreadCollection.timeSeries.map(
        (values: { key: string; value: { key: Date; value: number }[] }) => {
          const data = values.value.map(
            (v: { key: Date; value: number }) =>
              ({
                date: new Date(v.key),
                value: v.value,
              } as LineChartDataModel)
          );

          return {
            label: values.key,
            data: data,
            type: 'line',
            yAxisTitle: spreadCollection.unit,
            pane: 'topPane',

            // axis: 'y',
          } as Partial<LineChartModel>;
        }
      );

      multiChart.barChartData = spreadCollection.timeSeries.map(
        (values: { key: string; value: { key: Date; value: number }[] }) => {
          const data = values.value.map(
            (v: { key: Date; value: number }) =>
              ({
                date: new Date(v.key),
                value: v.value,
              } as LineChartDataModel)
          );

          return {
            label: values.key,
            data: data,
            type: 'bar',
            yAxisTitle: spreadCollection.unit,
            axis: 'bar',
            pane: 'bottomPane',
            aggregate: {
              method: 'avg',
            },

            // opacity: 0.3,
          } as Partial<LineChartModel>;
        }
      );

      return multiChart;
    })
  );

  @ViewChild('chartSpread')
  chartSpread: MultiPanelChartComponent;

  @ViewChild('chartPrices')
  chartPrices?: MultiPanelChartComponent;

  private closeSubject$ = new Subject<void>();

  constructor(private element: ElementRef) {}
  ngOnInit() {
    fromResize(this.element.nativeElement)
      .pipe(distinct(), takeUntil(this.closeSubject$))
      .subscribe(() => {
        this.updateCharts();
      });
  }

  ngOnDestroy(): void {
    this.closeSubject$.next();
    this.closeSubject$.complete();
  }

  public updateCharts() {
    this.chartSpread?.render();
    this.chartPrices?.render();
  }

  public getDailyXAxisMaxValue(): string {
    //4 days offset
    if (dayjs() < dayjs().date(1).add(4, 'day')) {
      return null;
    }
    return dayjs().endOf('month').format();
  }
}
