import { inject } from '@angular/core';
import { WidgetTypeProvider } from '@clean-code/shared/components/ui-dashboard';
import { CustomersContractVolumeConfigComponent } from './customer-contract-volume/customer-contract-volume-config/customer-contract-volume-config.component';
import { CustomersContractVolumeContentComponent } from './customer-contract-volume/customer-contract-volume-content/customer-contract-volume-content.component';
import { MarketCustomerAnalysisWidgetConfigComponent } from './market-customer-analysis-widget-type/market-customer-analysis-widget-config/market-customer-analysis-widget-config.component';
import { MarketCustomerAnalysisWidgetContentComponent } from './market-customer-analysis-widget-type/market-customer-analysis-widget-content/market-customer-analysis-widget-content.component';
import { MarketHknCustomersConfigComponent } from './market-hkn-customers/market-hkn-customers-config/market-hkn-customers-config.component';
import { MarketHknCustomersContentComponent } from './market-hkn-customers/market-hkn-customers-content/market-hkn-customers-content.component';
import { MarketHknDistributionConfigComponent } from './market-hkn-distribution/market-hkn-distribution-config/market-hkn-distribution-config.component';
import { MarketHknDistributionContentComponent } from './market-hkn-distribution/market-hkn-distribution-content/market-hkn-distribution-content.component';
import { MarketHknPositionsConfigComponent } from './market-hkn-positions/market-hkn-positions-config/market-hkn-positions-config.component';
import { MarketHknPositionsContentComponent } from './market-hkn-positions/market-hkn-positions-content/market-hkn-positions-content.component';
import { MarketOverviewWidgetConfigComponent } from './market-overview-widget-type/market-overview-widget-config/market-overview-widget-config.component';
import { MarketOverviewWidgetContentComponent } from './market-overview-widget-type/market-overview-widget-content/market-overview-widget-content.component';
import { MarketSegmentsContractVolumeConfigComponent } from './market-segments-contract-volume/market-segments-contract-volume-config/market-segments-contract-volume-config.component';
import { MarketSegmentsContractVolumeContentComponent } from './market-segments-contract-volume/market-segments-contract-volume-content/market-segments-contract-volume-content.component';
import { WidgetTypes } from './widget-types.model';

export function addMarketWidgets() {
  const widgetTypes = inject(WidgetTypeProvider);
  widgetTypes.add(
    WidgetTypes.MarketOverview,
    MarketOverviewWidgetConfigComponent,
    MarketOverviewWidgetContentComponent,
    ['backoffice', 'frontoffice', 'frontoffice-int'],
    12,
    6
  );

  widgetTypes.add(
    WidgetTypes.MarketHKNDistribution,
    MarketHknDistributionConfigComponent,
    MarketHknDistributionContentComponent,
    ['backoffice'],
    22,
    6,
    ['dashboard.admin']
  );
  widgetTypes.add(
    WidgetTypes.MarketSegmentsContractVolume,
    MarketSegmentsContractVolumeConfigComponent,
    MarketSegmentsContractVolumeContentComponent,
    ['backoffice'],
    12,
    6,
    ['dashboard.admin']
  );
  widgetTypes.add(
    WidgetTypes.CustomersContractVolume,
    CustomersContractVolumeConfigComponent,
    CustomersContractVolumeContentComponent,
    ['backoffice'],
    12,
    6,
    ['dashboard.admin']
  );
  widgetTypes.add(
    WidgetTypes.MarketHKNCustomers,
    MarketHknCustomersConfigComponent,
    MarketHknCustomersContentComponent,
    ['backoffice'],
    12,
    6,
    ['dashboard.admin']
  );
  widgetTypes.add(
    WidgetTypes.MarketHKNPositions,
    MarketHknPositionsConfigComponent,
    MarketHknPositionsContentComponent,
    ['backoffice'],
    22,
    6,
    ['dashboard.admin']
  );
  widgetTypes.add(
    WidgetTypes.MarketCustomerAnalysis,
    MarketCustomerAnalysisWidgetConfigComponent,
    MarketCustomerAnalysisWidgetContentComponent,
    ['backoffice'],
    24,
    8,
    ['dashboard.admin']
  );
}
