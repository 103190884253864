<div class="flex flex-col w-full h-full">
  @if (isLoading()) {
    <cc-progress-bar></cc-progress-bar>
  }
  @if (dashboard(); as dashboard) {
    <div
      class="flex flex-row justify-between"
      >
      <div class="pl-2 pt-2 title">
        {{ dashboard.name.localizations[0].value }}
      </div>
      @if (allowedEdit()) {
        <div page-buttons class="flex flex-row">
          <ng-container *ngVar="editMode(); let editMode">
            <ng-container *ngVar="hasUserPermission(); let hasUserPermission">
              @if (!editMode && hasUserPermission) {
                <cc-edit-action-button
                  (onClick)="edit()"
                ></cc-edit-action-button>
              }
              @if (editMode) {
                <cc-action-button
                  [icon]="faPlus"
                  displayText="DASHBOARDPANEL.ADD_WIDGET"
            title="{{
              (!hasUserPermission
                ? 'DASHBOARDPANEL.DIALOG-TITLE.NOWIDGETADD'
                : 'DASHBOARDWIDGET.DIALOG-TITLE.ADD'
              ) | transloco: { user: dashboard.creator.fullName }
            }}"
                  [disabled]="!hasUserPermission"
                  (onClick)="addWidget()"
                  >
                </cc-action-button>
              }
              @if (editMode) {
                <cc-action-button
                  [icon]="faShare"
                  displayText="DASHBOARDPANEL.REFERENZ_WIDGET"
            title="{{
              (!hasUserPermission
                ? 'DASHBOARDPANEL.DIALOG-TITLE.NOWIDGETADD'
                : 'DASHBOARDWIDGET.DIALOG-TITLE.REFERENCE'
              ) | transloco: { user: dashboard.creator.fullName }
            }}"
                  [disabled]="!hasUserPermission"
                  (onClick)="referenceWidget()"
                  >
                </cc-action-button>
              }
              @if (editMode) {
                <cc-save-action-button
                  (onClick)="edit()"
                ></cc-save-action-button>
              }
            </ng-container>
          </ng-container>
        </div>
      }
    </div>
  } @else {
    <!-- {{ 'DASHBOARDPANEL.SERVER-ERROR.DASHBOARD-NOT-VISIBLE' | transloco }} -->
    <cc-loading-indicator></cc-loading-indicator>
  }
  <div style="height: 100%">
    <dashboard-dashboard-panel-widgets></dashboard-dashboard-panel-widgets>
  </div>
</div>
