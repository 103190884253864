import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { EnergySourceEnum } from '@clean-code/shared/bo/util-enum';

@Injectable({ providedIn: 'root' })
export abstract class EnergySourceResolver {
  resolve(route: ActivatedRouteSnapshot): EnergySourceEnum {
    return route.paramMap.get('energySource') as EnergySourceEnum;
  }
}
