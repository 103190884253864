import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NotificationService } from '@backoffice-frontend/notifications/util-api';
import { NotificationCenterComponent } from '@clean-code/notifications/feature-notification-center';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@jsverse/transloco';
import { UserLimitViolationNotificationItemComponent } from './components/user-limit-violation-notification-item/user-limit-violation-notification-item.component';

@NgModule({
  imports: [
    CommonModule,

    RouterModule,

    MatIconModule,
    MatTooltipModule,
    TranslocoModule,
    FontAwesomeModule,
    NotificationCenterComponent,
  ],
  declarations: [UserLimitViolationNotificationItemComponent],
})
export class UserLimitUiViolationNotificationModule {
  constructor(notificationService: NotificationService) {
    notificationService.registerNotificationType({
      componentType: UserLimitViolationNotificationItemComponent,
      name: 'UserLimitViolation',
    });
  }
}
