import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Injector,
  ViewEncapsulation,
} from '@angular/core';
import {
  CalculationType,
  TimeSeriesInput,
} from '@backoffice-frontend/time-series-identifier/api';
import { indicate } from '@clean-code/shared/common';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { BaseWidgetTypeContentComponent } from '@clean-code/shared/components/ui-dashboard';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { MarketOverviewWidgetDialogComponent } from '../market-overview-widget-dialog/market-overview-widget-dialog.component';
import { OverviewWidgetService } from '../services/overview-widget.service';
import { MarketOverviewWidgetData } from './market-overview-widget-content.models';
@Component({
  standalone: true,
  selector: 'dashboard-market-overview-widget-content',
  templateUrl: './market-overview-widget-content.component.html',
  styleUrls: ['./market-overview-widget-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ProgressBarComponent],
})
export class MarketOverviewWidgetContentComponent extends BaseWidgetTypeContentComponent<TimeSeriesInput> {
  @HostBinding('class') class = 'dashboard-market-overview-widget-content';

  public data$: Observable<MarketOverviewWidgetData>;
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public calculationType: CalculationType;
  public longName: string;

  private confirmService: ConfirmService;

  public constructor(injector: Injector) {
    super();
    const service = injector.get(OverviewWidgetService);
    this.confirmService = injector.get(ConfirmService);
    const translateService = injector.get(TranslocoService);

    this.data$ = combineLatest([
      this.config$,
      translateService.langChanges$,
    ]).pipe(
      map(([config, _lang]) => config),
      filter(
        (value: TimeSeriesInput) =>
          !!value && !!value.identifier && !!value.identifier,
      ),
      switchMap((value: TimeSeriesInput) => {
        this.calculationType = value.identifier.calculationType;
        return service
          .marketOverviewWidgetData$(value)
          .pipe(indicate(this.isLoading$));
      }),
      map((value: MarketOverviewWidgetData) => {
        this.longName = value.longName;
        return {
          ...value,
          diffAbsolute: (value.currentValue || 0) - (value.previousValue || 0),
          diffPercent: value.currentValue / value.previousValue - 1,
        };
      }),
    );
  }

  //  !TODO: update to v18
  public openPopup() {
    this.confirmService
      .open(
        null,
        null,
        MarketOverviewWidgetDialogComponent,
        { longName: this.longName, timeSeriesInput: this.config },
        {
          maxWidth: '100vw',
          maxHeight: '100vh',
          width: '90vw',
          //height: '90vh',
        },
      )
      .subscribe();
  }
}
