import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ContactsPermissions } from '@backoffice/shared/bo/util-permissions';
import { EnergySourceDropdownComponent } from '@clean-code/shared/bo/components/ui-energy-source-dropdown';
import {
  BaseTableFilter,
  FilterKeyConditionType,
  SearchTableFilterComponent,
} from '@clean-code/shared/components/ui-data-table';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SearchRowDirective } from 'shared/directives/ui-layout';
@Component({
  standalone: true,
  selector: 'backoffice-standardized-loadprofile-search',
  templateUrl: './standardized-load-profile-search.component.html',
  imports: [
    ReactiveFormsModule,

    MatSlideToggleModule,
    NgxPermissionsModule,
    TranslocoModule,

    SearchTableFilterComponent,
    EnergySourceDropdownComponent,
    SearchRowDirective,
  ],
})
export class StandardizedLoadProfileSearchComponent extends BaseTableFilter {
  public contactsPermission = ContactsPermissions;

  public override filterControl = new UntypedFormGroup({
    energySource: new UntypedFormControl(null),
  });

  setFilterKeyConditions(): FilterKeyConditionType[] {
    const energySourceValue = this.filterControl.get('energySource').value;
    return [this.energySourceCondition(energySourceValue)];
  }

  energySourceCondition(energySource: string | null) {
    if (energySource) {
      return {
        key: 'energySource',
        conditions: `{ energySource: { eq: ${energySource} } }`,
      };
    } else {
      return { key: 'energySource', conditions: '' };
    }
  }
}
