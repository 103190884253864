import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, computed, inject } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { NotificationCenterComponent } from '@clean-code/notifications/feature-notification-center';
import {
  AuthInjectorToken,
  AuthStore,
  type IAuthService,
} from '@clean-code/shared/auth/util-auth';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faUser } from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-user-menu',
  templateUrl: './user-menu.component.html',

  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatButtonToggleModule,

    FontAwesomeModule,
    TranslocoModule,
    NotificationCenterComponent,
  ],
  // encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent {
  #authStore = inject(AuthStore);
  private readonly authService = inject<IAuthService>(AuthInjectorToken);
  @HostBinding('class') class = 'cc-user-menu';

  public faUser = faUser;
  public faChevronDown = faChevronDown;
  public fullName = computed(() => this.#authStore.user()?.fullName);
  public userId = this.#authStore.id;
  public showKam = computed(
    () => this.#authStore.user()?.companies?.length > 0,
  );

  public showUserInfo = false;

  @Input()
  mobile = false;

  toggleUserInfo(): void {
    this.showUserInfo = !this.showUserInfo;
  }

  logOut() {
    this.authService.logout();
  }
}
