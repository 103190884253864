import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { EnumService } from '@backoffice-frontend/shared/bo/util-masterdata';
import { BaseFormulaTypeConfigComponent } from '@clean-code/feature/formula-builder/domain';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { conditionalValidator } from '@clean-code/shared/util/util-form';
import { TranslocoModule } from '@jsverse/transloco';

export enum BenchmarkComponentTypes {
  MethodeType = 'BenchmarkFormulaMethod',
}

@Component({
  standalone: true,
  templateUrl: './benchmark-method.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,

    TranslocoModule,
    UtilDateModule,
  ],
})
export class BenchmarkMethodeComponent extends BaseFormulaTypeConfigComponent {
  private enumService = inject(EnumService);

  public timeFrameTypes$ = this.enumService.getEnumType$('TimeFrameType');

  formGroup = new FormGroup({
    startDate: new FormControl<Date>(null),
    timeFrameType: new FormControl<string>(null),
    value: new FormControl<number>(null, [
      conditionalValidator(
        () => this.formGroup.get('timeFrameType').value != null,
        Validators.required
      ),
    ]),
  });
}
