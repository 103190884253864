import { IAuditDto, IVisibleToItem, LocalizationSet } from '@clean-code/shared/common';
import { IDashboardWidgetDetailDto } from '@clean-code/shared/components/ui-dashboard';
import { ID } from '@datorama/akita';
import { IDashboardDetailDto, IDashboardPanelPreviewDto } from './dashboard.models';

export interface DashboardResponse {
  name: LocalizationSet;
  description?: LocalizationSet;
  color: string;
  shared: boolean;
  visibleTo: string;
  // visibleToObj: IVisibleToItem[];
  widgets: DashboardWidget[];
  tenant: string;
  createdBy: string;
  updatedBy: string;
  createdDate: Date;
  updatedDate: string;
  id: number;
  creator: IAuditDto;
}

export interface DashboardWidget {
  dashboardId: ID;
  sizeY: number;
  sizeX: number;
  positionY: number;
  positionX: number;
  widget: WidgetResponse;
}

export interface Widget {
  createdDate: Date;
  id: ID;
  name: LocalizationSet;
  description?: LocalizationSet;
  type: string;
  color: string;
  shared: boolean;
  visibleTo: IVisibleToItem[];
  config: string;
  creator: Creator;
}

export interface Creator {
  id: string;
  fullName: string;
}

export interface WidgetResponse {
  createdDate: Date;
  id: ID;
  name: LocalizationSet;
  description?: LocalizationSet;
  type: string;
  color: string;
  shared: boolean;
  visibleToObj: IVisibleToItem[];
  config: string;
  createdBy: ID;
  creator: IAuditDto;
}

export const dashboardResponseToDashboardDetailDto = (
  value: DashboardResponse
): IDashboardDetailDto => {
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    color: value.color,
    shared: value.shared,
    visibleTo: value.visibleTo,
    widgets: value.widgets.map((w) =>
      widgetResponseToDashboardWidgetDetailDto(w, value.tenant.split(';'))
    ),

    tenant: value.tenant.split(';'),
    creator: {
      id: value.creator.id,
      fullName: value.creator.fullName,
      date: value.createdDate
    }
  };
};

export const dashboardResponseToDashboard = (
  value: DashboardResponse
): IDashboardPanelPreviewDto => {
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    color: value.color,
    shared: value.shared,
    visibleTo: value.visibleTo,
    tenant: value.tenant.split(';'),
    creator: {
      id: value.creator.id,
      fullName: value.creator.fullName,
      date: value.createdDate
    }
  };
};

export const widgetResponseToDashboardWidgetDetailDto = (
  w: DashboardWidget,
  tenant: string[]
): IDashboardWidgetDetailDto => {
  return {
    id: w.widget.id,
    dashboardId: w.dashboardId,
    x: w.positionX,
    y: w.positionY,
    cols: w.sizeX,
    rows: w.sizeY,
    type: w.widget.type,
    name: w.widget.name,
    shared: w.widget.shared,
    config: w.widget.config,
    visibleTo: w.widget.visibleToObj,
    color: w.widget.color,
    tenant: tenant,
    creator: {
      id: w.widget.creator.id,
      fullName: w.widget.creator.fullName,
      date: w.widget.createdDate
    },
    minItemCols: 0,
    minItemRows: 0
  };
};
