<div class="flex flex-col w-full space-y-3 h-full">
  <cc-chart-container
    [isLoading]="isLoading$ | async"
    class="rounded-lg pt-1 pr-2 pl-1 mt-2"
    [ngClass]="{ '!h-96': !isWidget, '!h-full': isWidget }"
    >
    @if (!isWidget) {
      <div class="title" chart-title>
        {{ 'spread-chart.SPREADS' | transloco }}
      </div>
    }
    <ng-container chart-buttons>
      <form [formGroup]="formGroupSpread" class="flex flex-row space-x-2">
        <mat-checkbox formControlName="showAvg">{{
          'spread-chart.MONTHLY_MEAN' | transloco
        }}</mat-checkbox>
        <mat-checkbox formControlName="showTimeSeries">{{
          'spread-chart.SPREADS' | transloco
        }}</mat-checkbox>
      </form>
    </ng-container>
    <cc-multi-panel-chart
      #chartSpread
      chart-content
      [data]="spread$ | async"
      [showPane]="showSpread$ | async"
      [aggregationInterval]="aggregationInterval$ | async"
    ></cc-multi-panel-chart>
  </cc-chart-container>

  @if (!isWidget) {
    <cc-chart-container
      [isLoading]="isLoading$ | async"
      class="mt-1.5 pt-1 pr-2 pl-1"
      [ngClass]="{ '!h-96': !isWidget, '!h-full': isWidget }"
      >
      @if (!isWidget) {
        <div class="title" chart-title>
          {{ 'spread-chart.QUOTATIONS' | transloco }}
        </div>
      }
      <ng-container chart-buttons>
        <form [formGroup]="formGroupPrice" class="space-x-2">
          <mat-checkbox formControlName="showAvg">{{
            'spread-chart.MONTHLY_MEAN' | transloco
          }}</mat-checkbox>
          <mat-checkbox formControlName="showTimeSeries">{{
            'spread-chart.QUOTATIONS' | transloco
          }}</mat-checkbox>
        </form>
      </ng-container>
      <cc-multi-panel-chart
        #chartPrices
        chart-content
        [data]="avg$ | async"
        [showPane]="showPrice$ | async"
        [aggregationInterval]="aggregationInterval$ | async"
      ></cc-multi-panel-chart>
    </cc-chart-container>
  }
</div>
