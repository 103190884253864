import { Injectable } from '@angular/core';
import { IVisibleToItem } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class VisibleToDataService {
  public static readonly operationPrefix = 'visibleTo';
  public static readonly getParams = `
    key
    caption
  `;

  constructor(private graphqlService: GraphqlService) {}

  getAll$(
    search: string,
    limit: number,
    ignoreIds: string[]
  ): Observable<IVisibleToItem[]> {
    const query = `
      query($search: String, $limit: Int!, $ignoreIds: [String]) {
        ${VisibleToDataService.operationPrefix}GetAll(search: $search, limit: $limit, ignoreIds: $ignoreIds) {
            ${VisibleToDataService.getParams}
        }
      }
    `;

    return this.graphqlService.query<IVisibleToItem[]>(query, {
      search,
      limit,
      ignoreIds,
    });
  }
}
