<div class="flex flex-col h-full overflow-y-auto">
  <mat-horizontal-stepper #stepper [linear]="true">
    <mat-step>
      <ng-template matStepLabel>{{
        'DASHBOARDWIDGET.DIALOG-TITLE.CREATE-BASE' | transloco
      }}</ng-template>

      <ng-template matStepContent>
        <dashboard-base-widget-base-config
          #baseChild
          [widgetType]="widgetType"
        ></dashboard-base-widget-base-config>
      </ng-template>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{
        'DASHBOARDWIDGET.DIALOG-TITLE.CREATE-CONFIG' | transloco
      }}</ng-template>

      <ng-template matStepContent>
        <dashboard-widget-type-config #configChild>
        </dashboard-widget-type-config>
      </ng-template>
    </mat-step>
  </mat-horizontal-stepper>
  <div class="flex-grow"></div>
  <div
    class="flex flex-row justify-between items-center px-3"
    *ngVar="stepper.selectedIndex; let index"
    >
    <div class="flex flex-row justify-end space-x-1 my-1">
      <button
        mat-mini-fab
        class="mat-mini-fab-small"
        (click)="stepper.previous()"
        [disabled]="index <= 0"
        >
        &lt;
      </button>
      <button
        mat-mini-fab
        class="mat-mini-fab-small"
        [disabled]="baseChild?.form?.invalid || index > 0"
        (click)="stepper.next()"
        >
        >
      </button>
    </div>
    <div class="flex flex-row space-x-1">
      <cc-cancel-action-button mat-dialog-close></cc-cancel-action-button>
      @if (index > 0) {
        <cc-save-action-button
          [disabled]="baseChild?.form?.invalid || configChild?.form?.invalid"
          (onClick)="save()"
        ></cc-save-action-button>
      }
    </div>
  </div>
</div>
