import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationService } from '@backoffice-frontend/notifications/util-api';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@jsverse/transloco';
import { BuySignalNotificationItemComponent } from './components/buy-signal-notification-item.component';

@NgModule({
  imports: [
    CommonModule,

    MatIconModule,
    MatTooltipModule,
    TranslocoModule,
    FontAwesomeModule,
  ],
  declarations: [BuySignalNotificationItemComponent],
})
export class BuySignalNotificationModule {
  constructor(notificationService: NotificationService) {
    notificationService.registerNotificationType({
      componentType: BuySignalNotificationItemComponent,
      name: 'LimitReportBuySignal',
    });
  }
}
