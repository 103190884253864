<div mat-dialog-content>
  <div [formGroup]="form" [patchFormGroupValues]="config()">
    <div class="form-row">
      <div class="form-group col-lg-10">
        <time-series-dynamic-selection
          class="w-full"
          formGroupName="identifier"
        ></time-series-dynamic-selection>
      </div>
    </div>
  </div>
</div>
