<ng-template #headerTemplate let-data="data">
  <div class="h-6 mr-3 flex justify-end flex-shrink-0" (click)="expandChart()">
    <fa-icon
      [icon]="faMagnifyingGlassChart"
      class="text-base cursor-pointer hover:text-primary-color"
    ></fa-icon>
  </div>
</ng-template>

<fof-comparison-chart
  class="!px-1"
  [params]="data$ | async"
></fof-comparison-chart>
