
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BaseFormulaTypeConfigComponent } from '@clean-code/feature/formula-builder/domain';
import { TranslocoModule } from '@jsverse/transloco';
import { CustomMethodeForm } from './custom-methode';

export enum CustomMethodeComponentTypes {
  MethodeType = 'CustomMethod',
}

@Component({
  standalone: true,
  templateUrl: './custom-methode.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslocoModule
],
})
export class CustomMethodeComponent extends BaseFormulaTypeConfigComponent {
  formGroup = new FormGroup<CustomMethodeForm>({
    formula: new FormControl('', { nonNullable: true }),
  });
}
