import { Injectable } from '@angular/core';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { BaseFilterCompanySegment } from './models/filter-company-type';
@Injectable({
  providedIn: 'root',
})
export class BaseFilterService {
  private url = this.configService.settings['api'] + 'contacts/graphql';

  constructor(
    private graphqlService: GraphqlService,
    private configService: ConfigService
  ) {}

  public get$(): Observable<BaseFilterCompanySegment[]> {
    const query = `
      query GetCompanyType {
          companytypes {
            id
            name
            option
          }
        }
    `;
    return this.graphqlService.query<BaseFilterCompanySegment[]>(
      query,
      null,
      this.url
    );
  }
}
