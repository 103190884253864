import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CorrelationWidgetTypeConfig } from '@backoffice-frontend/market/domain';
import {
  EnumService,
  TimeFrame,
} from '@backoffice-frontend/shared/bo/util-masterdata';
import { DirectivesModule } from '@backoffice-frontend/shared/directives/common';
import { scopeLoader } from '@backoffice-frontend/shared/util-transloco';
import {
  TimeSeriesDynamicSelectionGroupComponent,
  TimeSeriesIdentifierInputForm,
} from '@backoffice-frontend/time-series-identifier/api';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { FormDirectiveModule } from '@clean-code/shared/directives/ui-form';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { atLeastOne } from '@clean-code/shared/util/util-form';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { Dayjs } from 'dayjs';
import { filter } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'correlation-chart-widget-settings',
  standalone: true,
  templateUrl: './correlation-chart-widget-settings.component.html',
  styleUrls: ['./correlation-chart-widget-settings.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatSelectModule,

    TranslocoModule,
    FontAwesomeModule,

    UtilDateModule,
    DirectivesModule,
    TimeSeriesDynamicSelectionGroupComponent,
    FormDirectiveModule,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'correlation-chart-widget-settings',
        alias: 'correlation-chart-widget-settings',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../${root}/${lang}.json`),
        ),
      },
    },
  ],
})
export class CorrelationChartWidgetSettingsComponent extends BaseWidgetTypeConfigComponent<CorrelationWidgetTypeConfig> {
  override form = new FormGroup(
    {
      settings: new FormGroup({
        timeFrame: new FormControl<TimeFrame>('M_3'),
        startDate: new FormControl<Dayjs>(null),
        type: new FormControl(null, [Validators.required]),
      }),
      identifier: new TimeSeriesIdentifierInputForm(),
      dataSources: new FormArray([]),
    },
    {
      validators: atLeastOne(Validators.required, [
        'settings.timeFrame',
        'settings.startDate',
      ]),
    },
  );

  private enumService = inject(EnumService);
  public timeFrames$ = this.enumService.getEnumType$('TimeSeriesTimeFrame');
  public baseTypes$ = this.enumService.getEnumType$('CorrelationType');

  public faTimes = faTimes;

  constructor() {
    super();

    this.form
      .get('settings.startDate')
      .valueChanges.pipe(
        filter((value: Dayjs) => !!value),
        tap(() => this.form.get('settings.timeFrame').reset()),
        takeUntil(this.closeSubject),
      )
      .subscribe();

    this.form
      .get('settings.timeFrame')
      .valueChanges.pipe(
        filter((value: TimeFrame) => !!value),
        tap(() => this.form.get('settings.startDate').reset()),
        takeUntil(this.closeSubject),
      )
      .subscribe();
  }

  public close() {
    //TODO: dashboard state
    // this.callback.next();
  }

  public get dataSources(): FormArray {
    return this.form.get('dataSources') as FormArray;
  }

  clearStartDate(event: any) {
    event.stopPropagation();
    this.form.get('settings.startDate').reset();
  }
}
