import { Injectable } from '@angular/core';

import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { Observable } from 'rxjs';
import { HealthcheckComponent } from '../healthcheck.component';

@Injectable({ providedIn: 'root' })
export class ManualServiceStartedGuard {
  constructor(private confirmService: ConfirmService) {}

  canDeactivate(
    component: HealthcheckComponent
  ): Observable<boolean> | boolean {
    if (!component.canDeactivate()) {
      return this.confirmService.confirm(
        'monitoring.EXECUTION_IN_PROGRESS_TITLE',
        'monitoring.EXECUTION_IN_PROGRESS',
        true,
        faExclamationCircle,
        '#ffb74d'
      );
    }
    return true;
  }
}
