import { inject, Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { map, Observable } from 'rxjs';
import { Message } from '../models/message';
import { NotificationCenterState } from '../state/notification-center.state';
import { NotificationService } from './notification.service';

@Injectable()
export class NotificationStateService {
  private state = inject(RxState<NotificationCenterState>);
  private notificationService = inject(NotificationService);

  private onNotificationReceive$ =
    this.notificationService.onNotificationReceive$.pipe(
      map((data) => {
        //sort by createDate DESC
        data = data.sort((a, b) => {
          return b.createDate.getTime() - a.createDate.getTime();
        });
        const unreadCount = data.filter((n: Message) => !n.isRead).length;

        this.state.set({ messages: data, unreadCount });

        return { messages: data, unreadCount };
      })
    );

  constructor() {
    this.state.connect(this.onNotificationReceive$);
    this.state.connect(
      'connectionState',
      this.notificationService.onConnected$
    );
  }

  public get state$(): Observable<NotificationCenterState> {
    return this.state.select();
  }

  public setAllAsRead(): void {
    const unreadMessages = this.state
      .get()
      .messages.filter((x: Message) => !x.isRead);

    if (unreadMessages.length > 0) {
      this.notificationService.setNotificationsRead(
        unreadMessages.map((x: Message) => x.id)
      );
    }
  }
}
