<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>

<div class="h-full w-full overflow-x-auto block">
  <dx-pivot-grid
    rowHeaderLayout="tree"
    [showBorders]="true"
    [dataSource]="dataSource$ | async"
    [allowSorting]="true"
    [allowFiltering]="true"
    [allowExpandAll]="true"
    [showRowTotals]="false"
    [showColumnTotals]="false"
    [showColumnGrandTotals]="false"
    (onCellPrepared)="onCellPrepared($event)"
    [wordWrapEnabled]="false"
    height="100%"
    [scrolling]="{ mode: 'standard', useNative: true }"
  >
    <dxo-field-chooser
      [allowSearch]="true"
      [enabled]="false"
    ></dxo-field-chooser>
  </dx-pivot-grid>
</div>
<div class="hidden">{{ 'export-table.VALUE' | transloco }}</div>
