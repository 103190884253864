import { animate, style, transition, trigger } from '@angular/animations';

export const animateFade = trigger('animateFade', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('300ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('300ms', style({ opacity: 1 })),
  ]),
]);

export const animateSlide = trigger('animateSlide', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(-100%)' }),
    animate('300ms', style({ opacity: 1, transform: 'translateX(0)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateX(0)' }),
    animate('300ms', style({ opacity: 0, transform: 'translateX(-100%)' })),
  ]),
]);
