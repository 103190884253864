import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IDashboardWidgetDto } from '@clean-code/shared/components/ui-dashboard';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  standalone: true,
  selector: 'widget-preview',
  templateUrl: './widget-preview.component.html',
  styleUrls: ['./widget-preview.component.scss'],
  imports: [CommonModule, TranslocoModule],
})
export class DashboardWidgetPreviewComponent {
  @Input() widget: IDashboardWidgetDto;
}
