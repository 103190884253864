<fa-icon
  class="icon-close"
  (click)="dialogRef.close()"
  class="top-10 right-10 float-right"
  >close</fa-icon
>

<div mat-dialog-title class="!flex">
  <h2 class="dialog-title">{{ longName }}</h2>
  <fa-icon
    class="cursor-pointer"
    size="1x"
    [icon]="faTimes"
    mat-dialog-close
  ></fa-icon>
</div>

<mat-dialog-content>
  <market-details [dataSource]="datasource"></market-details>
</mat-dialog-content>
