@if (isLoading()) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<div class="main-container p-3">
  <div class="card-container-wrapper">
    <div class="toolbar">
      <a
        mat-mini-fab
        color="primary"
        (click)="addDashboard()"
        class="text-center justify-center"
        >
        <fa-icon [icon]="faPlus" class="text-white"></fa-icon>
      </a>
    </div>

    @for (dashboard of userDashboards$ | async; track dashboard) {
      <div
        class="card-container card-animated"
        >
        <!-- <dashboard-panel-preview
        class="card-animated"
        [dashboard]="dashboard"
      ></dashboard-panel-preview> -->
    </div>
  }
</div>
</div>
