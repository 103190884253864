import { Injectable } from '@angular/core';
import { ConfigService } from '@clean-code/shared/util-config';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';
import { IMarketCustomerAnalysisWidgetContent } from '../market-customer-analysis-widget-content/market-customer-analysis-widget-content.models';
import { MarketCustomerAnalysisWidgetTypeConfig } from '../market-customer-analysis-widget.models';
import { WidgetCompany } from '../widget-company';

@Injectable({
  providedIn: 'root',
})
export class MarketCustomerAnalysisWidgetService {
  private url = this.configService.settings['api'] + 'contacts/graphql';

  constructor(
    private configService: ConfigService,
    private graphqlService: GraphqlService
  ) {}

  public dashboardCustomerOverviewById$(
    value: MarketCustomerAnalysisWidgetTypeConfig
  ): Observable<IMarketCustomerAnalysisWidgetContent> {
    const query = `
          query($id: Int!) {
            dashboardCustomerOverviewById(id: $id)
            {
              customer {
                name
                segment
                kam
                {
                  power
                  gas
                }
                active
                users {
                  active
                  inactive
                }
              }
              loadProfile {
                active
                inactive
              }
              forecast {
                active
                inactive
              }
              offer {
                active
                inactive
              }
              hkn {
                open
                closed
              }
              ieb {
                open
                closed
                intraday
                spot
                limitOrder
                spreadSecurity {
                  open
                  closed
                }
                strategyMssages
              }

              limitReports
            }
          }`;

    return this.graphqlService.query<IMarketCustomerAnalysisWidgetContent>(
      query,
      {
        id: +value.companyId,
      },
      null
    );
  }

  public getAllCompanies$(): Observable<WidgetCompany[]> {
    const query = `
      query {
        all_companies {
          id
          name
          segmentId
          segment {
            name
          }
          disabled
          personId
          superId
          partnerIdPower
          partnerIdGas
        }
      }
    `;

    return this.graphqlService.query<WidgetCompany[]>(query, null, this.url);
  }
}
