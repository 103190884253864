import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { indicate } from '@clean-code/shared/common';
import { BaseWidgetTypeContentComponent } from '@clean-code/shared/components/ui-dashboard';
import { ProgressBarComponent } from '@clean-code/shared/components/ui-progress-bar';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { HknPositionsService } from '../services/hkn-positions.service';
import { MarketHknPositionsWidgetTypeConfig } from './../market-hkn-positions';

@Component({
  standalone: true,
  selector: 'dashboard-market-hkn-positions-content',
  templateUrl: './market-hkn-positions-content.component.html',
  styleUrls: ['./market-hkn-positions-content.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, ProgressBarComponent, DxPieChartModule],
})
export class MarketHknPositionsContentComponent extends BaseWidgetTypeContentComponent<MarketHknPositionsWidgetTypeConfig> {
  public isLoading$ = new BehaviorSubject<boolean>(false);

  public service = inject(HknPositionsService);
  public data$ = this.configObservable.pipe(
    filter((value: MarketHknPositionsWidgetTypeConfig) => !!value.filter),
    switchMap((value: MarketHknPositionsWidgetTypeConfig) =>
      this.service
        .marketOverviewWidgetData$(value.filter)
        .pipe(indicate(this.isLoading$))
    )
  );

  customizeTooltip(arg: any) {
    return {
      text: `${arg.argumentText}<br>${arg.seriesName}: ${arg.valueText}B`,
    };
  }
}
