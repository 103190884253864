import { Component, inject } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CompanyRelationTypeEnum } from '@backoffice/contacts/domain';
import { ContactsPermissions } from '@backoffice/shared/bo/util-permissions';
import { AuthStore } from '@clean-code/shared/auth/util-auth';
import { EnergySourceDropdownComponent } from '@clean-code/shared/bo/components/ui-energy-source-dropdown';
import {
  BaseTableFilter,
  FilterKeyConditionType,
  SearchTableFilterComponent,
} from '@clean-code/shared/components/ui-data-table';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
@Component({
  standalone: true,
  selector: 'backoffice-loadprofile-search',
  templateUrl: './load-profile-search.component.html',
  imports: [
    ReactiveFormsModule,
    MatSlideToggleModule,
    NgxPermissionsModule,
    TranslocoModule,

    SearchTableFilterComponent,
    EnergySourceDropdownComponent,
  ],
})
export class LoadProfileSearchComponent extends BaseTableFilter {
  public contactsPermission = ContactsPermissions;
  #authStore = inject(AuthStore);

  public override filterControl = new UntypedFormGroup({
    energySource: new UntypedFormControl(null),
    onlyOwned: new UntypedFormControl(false),
  });

  setFilterKeyConditions(): FilterKeyConditionType[] {
    const onlyOwnedValue = this.filterControl.get('onlyOwned').value;
    const energySourceValue = this.filterControl.get('energySource').value;
    const conditionsArr: FilterKeyConditionType[] = [
      this.energySourceCondition(energySourceValue),
      this.onlyOwnedCondition(onlyOwnedValue, energySourceValue),
    ];

    return conditionsArr;
  }

  energySourceCondition(energySource: string | null): FilterKeyConditionType {
    if (energySource) {
      return {
        key: 'energySource',
        conditions: `{ energySource: { eq: ${energySource} } }`,
      };
    } else {
      return { key: 'energySource', conditions: '' };
    }
  }

  onlyOwnedCondition(
    onlyOwned: boolean,
    energySource: string,
  ): FilterKeyConditionType {
    const userId = this.#authStore.id();
    if (onlyOwned && energySource) {
      const type =
        energySource === 'POWER'
          ? CompanyRelationTypeEnum.KeyAccountManagerPower
          : CompanyRelationTypeEnum.KeyAccountManagerGas;
      return {
        key: 'onlyOwned',
        conditions: `{company: {companies: {some: {and: [{ type: { eq: ${type} } },{ personId: { eq:"${userId}"} }]}}}}`,
      };
    } else if (onlyOwned) {
      return {
        key: 'onlyOwned',
        conditions: `{company: {companies: {some: {and: [{or: [{ type: { eq: ${CompanyRelationTypeEnum.KeyAccountManagerPower} } }, { type: { eq: ${CompanyRelationTypeEnum.KeyAccountManagerGas} } }]},{ personId: { eq:"${userId}"} }]}}}}`,
      };
    } else {
      return { key: 'onlyOwned', conditions: '' };
    }
  }
}
