import { Route } from '@angular/router';
import { ManualServiceStartedGuard } from './guard/manual-service-started.guard';
import { HealthcheckComponent } from './healthcheck.component';

export const healthCheckRoute: Route = {
  path: 'healthcheck',
  component: HealthcheckComponent,
  canDeactivate: [ManualServiceStartedGuard],
  children: [
    {
      path: 'preview/:id',
      loadChildren: () =>
        import('@clean-code/monitoring/feature-healthcheck-history').then(
          (mod) => mod.MonitoringFeatureHealthcheckHistoryModule
        ),
    },
  ],
};
