import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { addTimeSeriesIdentifierTableWidget } from '@backoffice/time-series-identifier/ui-table-widget';
import { addSpreadWidget } from '@clean-code/frontoffice/spread/ui-spread-widget';
import {
  CorrelationChartWidgetComponent,
  CorrelationChartWidgetSettingsComponent,
  CorrelationChartWidgetTypes,
} from '@clean-code/market/ui-correlation-chart-widget';
import {
  CorrelationWidgetComponent,
  CorrelationWidgetSettingsComponent,
  CorrelationWidgetTypes,
} from '@clean-code/market/ui-correlation-widget';
import {
  ExportTableSettingComponent,
  ExportWidgetComponent,
  ExportWidgetTypes,
} from '@clean-code/market/ui-export-table';
import { addMarketWidgets } from '@clean-code/shared/bo/widgets/ui-market-widgets';
import { WidgetTypeProvider } from '@clean-code/shared/components/ui-dashboard';
import { addTimeSeriesIdentifierChartWidget } from '@clean-code/time-series-identifier/ui-chart-widget';
import { addTimeSeriesIdentifierComparisonChartWidget } from '@clean-code/time-series-identifier/ui-comparison-chart-widget';

@NgModule({
  imports: [CommonModule],
})
export class DashboardModule {
  constructor(widgetTypes: WidgetTypeProvider) {
    widgetTypes.add(
      CorrelationWidgetTypes.WidgetType,
      CorrelationWidgetSettingsComponent,
      CorrelationWidgetComponent,
      ['backoffice', 'frontoffice', 'frontoffice-int']
    );

    widgetTypes.add(
      CorrelationChartWidgetTypes.WidgetType,
      CorrelationChartWidgetSettingsComponent,
      CorrelationChartWidgetComponent,
      ['backoffice', 'frontoffice', 'frontoffice-int']
    );

    widgetTypes.add(
      ExportWidgetTypes.WidgetType,
      ExportTableSettingComponent,
      ExportWidgetComponent,
      ['backoffice', 'frontoffice', 'frontoffice-int']
    );
    addMarketWidgets();
    addSpreadWidget();
    addTimeSeriesIdentifierChartWidget();
    addTimeSeriesIdentifierTableWidget();
    addTimeSeriesIdentifierComparisonChartWidget();
  }
}
