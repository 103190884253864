
import { Component, HostBinding, Inject, inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';
import { ID } from '@clean-code/shared/common';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { IDashboardWidgetDetailDto, IWidgetTypeProvider } from '@clean-code/shared/components/ui-dashboard';
import { DashboardWidgetFacade, WidgetStore } from '@clean-code/shared/dashboard/widget/domain';
import { WidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/ui-widget';
import { ToastService } from '@clean-code/shared/util/util-toast';
import { TranslocoModule } from '@jsverse/transloco';
import { Observable } from 'rxjs';
import { BaseWidgetBaseConfigComponent } from '../widget-base-edit/widget-base-edit.component';

@Component({
  standalone: true,
  templateUrl: './widget-add-dialog.component.html',
  styleUrls: ['./widget-add-dialog.component.scss'],
  imports: [
    MatStepperModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslocoModule,
    VarDirective,
    WidgetTypeConfigComponent,
    BaseWidgetBaseConfigComponent,
    ActionButtonModule
],
  providers: [WidgetStore, DashboardWidgetFacade]
})
export class DashboardWidgetAddDialogComponent {
  @HostBinding('class') class = 'dashboard-widget-edit';

  @ViewChild('stepper') public stepper: MatStepper;
  @ViewChild('baseChild', { static: false })
  public baseChild: BaseWidgetBaseConfigComponent;
  @ViewChild('configChild', { static: false })
  public configChild: WidgetTypeConfigComponent;

  public widgetType: IWidgetTypeProvider;
  public dashboardWidgetFacade = inject(DashboardWidgetFacade);

  constructor(
    public dialogRef: MatDialogRef<DashboardWidgetAddDialogComponent>,
    private toastService: ToastService, // private dashboardWidgetFacade: DashboardWidgetFacade
    @Inject(MAT_DIALOG_DATA)
    public data: { dashboardId: ID; tenant: string[] }
  ) {
    const inputWidget = {
      dashboardId: data.dashboardId,
      name: null,
      description: null,
      color: '#cdcdcd',
      shared: false,
      visibleTo: []
    } as IDashboardWidgetDetailDto;
    this.dashboardWidgetFacade.updateWidget(inputWidget);
  }

  public save() {
    this.dashboardWidgetFacade.updateWidgetWithSettings(
      this.baseChild.form.value,
      this.configChild.form.value
    );
    let request: Observable<IDashboardWidgetDetailDto>;
    if (this.dashboardWidgetFacade.widget().id) {
      request = this.dashboardWidgetFacade.update$(
        this.dashboardWidgetFacade.widget()
      );
    } else {
      request = this.dashboardWidgetFacade.add$(
        this.dashboardWidgetFacade.widget()
      );
    }
    request.subscribe((result) => {
      if (result) {
        this.toastService.showSuccess(
          'DASHBOARDWIDGET.MESSAGES.CREATE.SUCCESS'
        );

        this.dialogRef.close(result);
      }
    });
  }
}
