<div class="widget-reference-dialog-component">
  <div class="header">
    <h4 transloco="DASHBOARDWIDGET.DIALOG-TITLE.REFERENCE"></h4>

    <button mat-icon-button (click)="onClose()">
      <fa-icon [icon]="faXmark"></fa-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <dashboard-widget-reference
      (callback)="onSave($event)"
    ></dashboard-widget-reference>
  </div>
</div>
