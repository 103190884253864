import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IDashboardPanelPreviewDto } from '@clean-code/shared/dashboard/dashboard/domain';

@Component({
  standalone: true,
  selector: 'dashboard-panel-preview',
  templateUrl: './dashboard-panel-preview.component.html',
  styleUrls: ['./dashboard-panel-preview.component.scss'],
  imports: [CommonModule],
})
export class DashboardPanelPreviewComponent {
  @Input() dashboard: IDashboardPanelPreviewDto;
}
