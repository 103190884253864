import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SplashScreenService } from '@clean-code/shared/common';
import { environment } from '../environments/environment';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'backoffice-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [AsyncPipe, RouterModule],
})
export class AppComponent {
  private splashState = inject(SplashScreenService);
  public showSplash$ = this.splashState.showSplash$;

  constructor() {
    const titleService = inject(Title);

    titleService.setTitle(environment.name);
  }
}
