import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ComponentRef, HostBinding, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import {
  IDashboardWidgetConfigDto,
  IWidgetTypeConfigComponent,
  WidgetTypeProvider
} from '@clean-code/shared/components/ui-dashboard';
import { DashboardWidgetFacade } from '@clean-code/shared/dashboard/widget/domain';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { DynamicModule } from 'ng-dynamic-component';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  standalone: true,
  templateUrl: './widget-type-dialog-config.component.html',
  styleUrls: ['./widget-type-dialog-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,

    MatDialogModule,

    TranslocoModule,
    DynamicModule,

    FontAwesomeModule,
    ActionButtonModule
  ]
})
export class WidgetTypeConfigDialogComponent {
  @HostBinding('class') class = 'widget-type-dialog-config-component';

  faTimes = faTimes;
  public form!: UntypedFormGroup;
  // public component: { new (): IWidgetTypeConfigComponent };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public inputs$ = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public outputs: any;
  public component$ = new BehaviorSubject<{
    new(): IWidgetTypeConfigComponent;
  }>(null);

  private widgetFacade = inject(DashboardWidgetFacade);
  public widget = this.widgetFacade.widget;

  constructor(
    public dialogRef: MatDialogRef<WidgetTypeConfigDialogComponent>,
    private widgetTypeProvider: WidgetTypeProvider
  ) {
    const provider = this.widgetTypeProvider.getProvider(
      this.widget().type as string
    );
    if (provider) {
      this.component$.next(provider.configCmp);
    }
  }

  public close() {
    this.dialogRef.close();
  }

  public componentCreated(compRef: ComponentRef<IWidgetTypeConfigComponent>) {
    this.form = compRef.instance.form;
  }

  public save() {
    const widget = this.widget();
    const widgetConfig = {
      id: widget.id,
      dashboardId: widget.dashboardId,
      type: widget.type,
      config: this.form.value
    } as IDashboardWidgetConfigDto;

    if (!widgetConfig) {
      this.dialogRef.close();
      return;
    }

    this.widgetFacade.updateConfig$(widgetConfig);

    this.dialogRef.close();
  }
}
