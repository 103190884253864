import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { ColorPickerComponent } from '@clean-code/shared/components/ui-color-picker';
import { InputTranslationModule } from '@clean-code/shared/components/ui-translation';
import { VisibleToComponent } from '@clean-code/shared/components/ui-visible-to';
import {
  DashboardFacade,
  type IDashboardListDto,
  type IDashboardPanelPreviewDto
} from '@clean-code/shared/dashboard/dashboard/domain';
import { FormDirectiveModule } from '@clean-code/shared/directives/ui-form';
import { TenantService } from '@clean-code/shared/util/util-tenant';
import { TranslocoModule } from '@jsverse/transloco';
import { BehaviorSubject, type Observable } from 'rxjs';

@Component({
  standalone: true,
  selector: 'dashboard-dashboard-edit',
  templateUrl: './dashboard-edit.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    MatProgressBar,

    TranslocoModule,

    InputTranslationModule,
    ColorPickerComponent,
    FormDirectiveModule,
    VisibleToComponent
  ]
})
export class DashboardEditComponent {
  public dialogRef = inject(MatDialogRef<DashboardEditComponent>);
  private tenantService = inject(TenantService);
  private service = inject(DashboardFacade);

  public form = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    name: new UntypedFormControl(null, Validators.required),
    description: new UntypedFormControl(null),
    color: new UntypedFormControl('', Validators.required),
    shared: new UntypedFormControl(false),
    visibleTo: new UntypedFormControl(null),
    tenant: new UntypedFormControl(null, Validators.required)
  });
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public dashboard$: Observable<IDashboardPanelPreviewDto>;
  public tenants$ = this.tenantService.getAll$();

  @Input()
  public set dashboard(value: IDashboardListDto) {
    if (value?.id) {
      this.dashboard$ = this.service.getByIdWithTranslations$(value.id);
    }
  }

  get tenant() {
    return this.form.get('tenant');
  }
}
