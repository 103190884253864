import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { Dayjs } from 'dayjs';
export interface DateRange {
  startDate: Dayjs;
  endDate: Dayjs;
}

export interface DateRangeForm {
  startDate: FormControl<Dayjs>;
  endDate: FormControl<Dayjs>;
}

export class DateRangeInputForm extends FormGroup<DateRangeForm> {
  constructor(
    readonly model?: DateRange,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(
      fb.group({
        startDate: new FormControl<Dayjs>(null),
        endDate: new FormControl<Dayjs>(null),
      }).controls,
      validatorOrOpts
    );

    this.patchValue(model);
  }
}
