/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

import { AzureConfig } from '@clean-code/shared/auth/util-azure';
import { EnvConfig } from '@clean-code/shared/util-config';
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
declare let require: any;

const TENANT_GUID = '08f06a7d-6a4c-495c-a276-26cc8ac21409';
const CLIENT_ID = '17ab109c-3895-4c88-a31a-12dfdfbc2636';
const server_url = 'https://backoffice-api.cleancode-software.com';

export const protectedResources = {
  api: {
    endpoint: server_url,
    scopes: ['api://' + CLIENT_ID + '/user_impersonation'],
  },
};

const protectedResourceMap = new Map<string, Array<string>>();
// protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', [
//   'user.read',
// ]);

protectedResourceMap.set(
  protectedResources.api.endpoint,
  protectedResources.api.scopes
);

const authOption: AzureConfig = {
  auth: {
    clientId: CLIENT_ID, // PPE testing environment
    authority: 'https://login.microsoftonline.com/' + TENANT_GUID, // PPE testing environment.
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  protectedResourceMap: protectedResourceMap,
};

export const environment: EnvConfig = {
  production: false,
  env: 'dev',
  name: 'Procolix - DEV',
  api: server_url + '/api/',
  graphql: server_url + '/graphql',
  legacy_api: 'http://localhost:29418/',
  version: require('../../package.json').version,
  origins: ['localhost', server_url + '/api', server_url + '/graphql'],
  auth: authOption,
  signalR: {
    api: server_url + '/',
  },
  allowEditDashboard: true,
  healthCheckUrl: server_url,
  instrumentationKey: 'd75ab34a-5415-4645-8dca-1b9235eb1120',
};
