import { Route } from '@angular/router';
import { limitReportOtherDetails } from '@backoffice-frontend/limit-report/ui-other';

export const limitReportOtherRoute: Route = {
  path: '',
  children: [
    {
      path: '',
      loadChildren: () =>
        import('@backoffice-frontend/limit-report/ui-other').then(
          (mod) => mod.LimitReportOtherListModule
        ),
    },
    limitReportOtherDetails,
  ],
};
