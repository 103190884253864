import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EnumService } from '@backoffice-frontend/shared/bo/util-masterdata';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';
import { WidgetTypeConfig } from '@backoffice-frontend/time-series-identifier/domain';
import { TimeSeriesDynamicSelectionGroupComponent } from '@backoffice-frontend/time-series-identifier/ui-time-series-dynamic-selection';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { PatchFormGroupWithEmitValuesDirective } from '@clean-code/shared/directives/ui-form';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { TranslocoModule } from '@jsverse/transloco';
import { map, startWith } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-chart-config',
  templateUrl: './chart-config.component.html',
  styleUrls: ['./chart-config.component.css'],
  imports: [
    CommonModule,

    ReactiveFormsModule,

    MatFormFieldModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,

    TranslocoModule,

    VarDirective,
    TimeSeriesDynamicSelectionGroupComponent,
    UtilDateModule,
    PatchFormGroupWithEmitValuesDirective,
  ],
  providers: [provideNativeDateAdapter()],
})
export class ChartConfigComponent extends BaseWidgetTypeConfigComponent<WidgetTypeConfig> {
  override form = new UntypedFormGroup({
    settings: new UntypedFormGroup({
      startDate: new UntypedFormControl(null, [Validators.required]),
      showTable: new UntypedFormControl(true, [Validators.required]),
      showInPercentage: new UntypedFormControl(false, [Validators.required]),
    }),
    dataSources: new UntypedFormArray([]),
  });

  public calculationType$ = this.form
    .get('settings.showInPercentage')
    .valueChanges.pipe(
      startWith(false),
      map((value: boolean) => (value ? 'RELATIVE' : 'ABSOLUTE')),
    );

  private enumService = inject(EnumService);
  public timeFrames$ = this.enumService.getEnumType$('TimeSeriesTimeFrame');

  public get dataSources(): UntypedFormArray {
    return this.form.get('dataSources') as UntypedFormArray;
  }
}
