import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnergySourceEnum } from '@clean-code/shared/bo/util-enum';
import { ControlValueAccessorConnector } from '@clean-code/shared/components/ui-form-controls';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { KamDropDownService } from './kam-dropdown.service';

@Component({
  selector: 'bo-kam-dropdown',
  templateUrl: './kam-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KamDropdownComponent),
      multi: true,
    },
  ],
})
export class KamDropdownComponent extends ControlValueAccessorConnector {
  public energySource$ = new BehaviorSubject<string>(null);

  @Input()
  public set energySource(value: string) {
    this.energySource$.next(value);
  }

  dataFunction = (search: string) =>
    this.energySource$.pipe(
      switchMap((value: string) => {
        if (value === EnergySourceEnum.Gas) {
          return this.companyService.getAllGasKeyAccountManager$(search);
        }

        return this.companyService.getAllPowerKeyAccountManager$(search);
      })
    );

  constructor(private companyService: KamDropDownService) {
    super();
  }
}
