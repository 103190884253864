import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { WidgetBaseFilterModel } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import { ID } from '@clean-code/shared/common';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarketSegmentsContractVolumeContentService {
  constructor(private graphqlService: GraphqlService) {}

  public dashboardSegmentContractVolume$(
    value: WidgetBaseFilterModel,
  ): Observable<
    KeyValue<
      string,
      { volume: number; closed: number; companyId: ID; open: number }
    >[]
  > {
    const query = `
      query($input: WidgetBaseFilterInput!) {
        dashboardSegmentContractVolume(input: $input)
            {
                key
                value {
                  volume
                  open
                  closed
                }
              }
          }`;

    return this.graphqlService.query<
      KeyValue<
        string,
        { volume: number; closed: number; companyId: ID; open: number }
      >[]
    >(query, {
      input: value,
    });
  }
}
