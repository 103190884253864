<cc-dashboard-page-container>
  <ng-container title>
    {{ 'standardized-load-profile.TITLE_PLURAL' | transloco }}
  </ng-container>

  <cc-table-preview page-content>
    <cc-table-container [isLoading]="isLoading$ | async">
      <ng-container table-filter>
        <backoffice-standardized-loadprofile-search
          [searchTermProperties]="['name']"
        ></backoffice-standardized-loadprofile-search>
      </ng-container>

      <div table-buttons>
        <cc-add-action-button (onClick)="add()"></cc-add-action-button>
      </div>

      <bo-mat-table
        matSort
        [data]="paging$ | async"
        [showPaginator]="false"
        (pageChange)="setPage($event)"
        (matSortChange)="onSort($event)"
      >
        <ng-container matColumnDef="isImported">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'standardized-load-profile.upload.IS_IMPORTED' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">
            <fa-icon
              class="icon"
              [ngClass]="{
                'text-secondary-color-800': !element.isImported,
                'text-primary-color-600': element.isImported
              }"
              [icon]="faCheck"
            ></fa-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'standardized-load-profile.fields.NAME' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'standardized-load-profile.fields.DESCRIPTION' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="energySource">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'standardized-load-profile.fields.ENERGYSOURCE' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ 'bo.common.energysources.' + element.energySource | transloco }}
          </td>
        </ng-container>

        <ng-container matColumnDef="deliveryArea.name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'standardized-load-profile.fields.DELIVERY_AREA' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.deliveryArea.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'common.CREATED_BY' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdByDec.fullName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'common.CREATED_DATE' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdDate | date : 'shortDate' }}
          </td>
        </ng-container>
      </bo-mat-table>
    </cc-table-container>
  </cc-table-preview>
</cc-dashboard-page-container>
