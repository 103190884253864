import { Route } from '@angular/router';
import { ForecastPreviewResolver } from '@backoffice/forecast/domain';
import { ForecastPermissions } from '@backoffice/shared/bo/util-permissions';
import { NgxPermissionsGuard } from 'ngx-permissions';

export const forecastListRoute: Route = {
  path: 'forecasts',
  loadComponent: () =>
    import('./forecast-list/forecast-list.component').then(
      (mod) => mod.ForecastListComponent
    ),
  canActivate: [NgxPermissionsGuard],
  data: {
    permissions: {
      only: ForecastPermissions.forecastSideNavPermisssion,
    },
  },
  children: [
    {
      path: 'preview/:id',
      loadComponent: () =>
        import('./forecast-preview/forecast-preview.component').then(
          (mod) => mod.ForecastPreviewComponent
        ),
      resolve: {
        entity: ForecastPreviewResolver,
      },
    },
  ],
};
