import { CommonModule } from '@angular/common';
import { Component, forwardRef, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorConnector } from '@clean-code/shared/components/ui-form-controls';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { VisibleToDataService } from './visible-to-data.service';

@Component({
  standalone: true,
  selector: 'cc-visible-to',
  templateUrl: './visible-to.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,

    NgSelectModule,
    TranslocoModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VisibleToComponent),
      multi: true
    }
  ]
})
export class VisibleToComponent extends ControlValueAccessorConnector {

  private visibleToDataService = inject(VisibleToDataService);

  public data$ = this.visibleToDataService
    .getAll$(
      null,
      1000,
      null
    );


}
