import { Inject, Injectable, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  type AuthenticationResult,
  type EventMessage,
  EventType,
  type RedirectRequest,
} from '@azure/msal-browser';
import { AuthStore, IAuthService } from '@clean-code/shared/auth/util-auth';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AzureAuthService implements IAuthService {
  #authStore = inject(AuthStore);

  constructor(
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    msalBroadcastService: MsalBroadcastService,
  ) {
    msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS,
        ),
        distinctUntilChanged(),
        tap((msg: EventMessage) => {
          this.#authStore.setAccessToken(
            (msg.payload as AuthenticationResult).accessToken,
          );
        }),
      )
      .subscribe();
  }

  hasValidIdToken(): boolean {
    throw new Error('Method not implemented.');
  }

  hasValidAccessToken(): boolean {
    throw new Error('Method not implemented.');
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.#authStore.reset();
    this.authService.logoutRedirect();
  }

  getAccessToken$(): Observable<string> {
    return toObservable(this.#authStore.accessToken);
  }

  async getAccessToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const accessToken = this.#authStore.accessToken();

      if (!accessToken) {
        reject('no access token');
      }

      resolve(accessToken);
    });
  }
}
