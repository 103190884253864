import { Directive, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IFormulaTypeConfigComponent } from '../entities/formula-type-config-component';

@Directive()
export abstract class BaseFormulaTypeConfigComponent
  implements IFormulaTypeConfigComponent
{
  @Input({ required: true })
  public set config(value: any) {
    this.formGroup.patchValue(value);
  }

  public abstract formGroup: UntypedFormGroup;
}
