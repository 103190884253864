import { Route } from '@angular/router';
import { StandardizedLoadProfileListComponent } from './standardized-load-profile-list.component';

export const standardizedLoadProfileListRoute: Route = {
  path: 'standardized-load-profiles',
  component: StandardizedLoadProfileListComponent,
  children: [
    {
      path: 'preview/:id',
      loadComponent: () =>
        import(
          './standardized-load-profile-preview/standardized-load-profile-preview.component'
        ).then((mod) => mod.StandardizedLoadProfilePreviewComponent),
    },
  ],
};

// @NgModule({
//   imports: [
//     CommonModule,
//     ReactiveFormsModule,
//     RouterModule.forChild(routes),
//     MatTooltipModule,
//     MatSelectModule,
//     MatFormFieldModule,
//     MatInputModule,

//     TranslocoModule,
//     FontAwesomeModule,
//     NgxPermissionsModule,

//     UiMatTableModule,
//     ActionButtonModule,
//     DirectivesModule,
//     PageContainerModule,

//     StandardizedLoadProfileSearchComponent,
//     // EnergySourceDropdownFilterLegacyComponent,
//     // SearchTableFilterLegacyComponent,
//   ],
//   declarations: [StandardizedLoadProfileListComponent],
//   exports: [StandardizedLoadProfileListComponent],
// })
// export class StandardizedLoadProfileListModule {}
