import { Injectable, inject } from '@angular/core';
import { type ID, localizationLongAllFragment, localizationLongFragment, localizationMediumAllFragment, localizationMediumFragment } from '@clean-code/shared/common';
import type { IDashboardWidgetCorrelationsUpdateInput } from '@clean-code/shared/components/ui-dashboard';
import { getWidgetDetailParams } from '@clean-code/shared/dashboard/widget/api-widget';
import { GraphqlService } from '@clean-code/shared/util-graphql';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  type DashboardResponse,
  dashboardResponseToDashboard,
  dashboardResponseToDashboardDetailDto
} from '../models/dashboard-response';
import type {
  IDashboardDetailDto,
  IDashboardListDto,
  IDashboardPanelPreviewDto,
  IDashboardReferenceDto,
  IDashboardUserCorrelation
} from './../models/dashboard.models';

@Injectable({ providedIn: 'root' })
export class DashboardDataService {
  private graphqlService = inject(GraphqlService);
  public static readonly getParams = `
  key
  caption
`;

  public static readonly getAssignableParams = `
    id
    name {
      ...localizationMediumFragment
    }
    color
    description {
      ...localizationLongFragment
    }
    creator {
      id
      fullName
    }
    createdDate
  `;

  public static readonly getListParams = `
    dashboard {
        id
        name {
          ...localizationMediumFragment
        }
        description {
          ...localizationLongFragment
        }
        color
        shared
        visibleTo
        tenant
        createdDate
        createdBy
        creator
        {
          id
          fullName
        }
    }
  `;

  public static readonly getDetailParams = `
    id
    name {
      ...localizationMediumFragment
    }
    description {
      ...localizationLongFragment
    }
    color
    shared
    visibleTo
    widgets {
      dashboardId
      positionX
      positionY
      sizeX
      sizeY
      widget{
        ${getWidgetDetailParams}
      }
    }
    createdBy
    creator {
      id
      fullName
    }

    tenant
  `;

  public static readonly getDetailWithoutWidgetsParams = `
      id
      name {
        ...localizationMediumFragment
      }
      description {
        ...localizationLongFragment
      }
      color
      shared
      visibleTo
      visibleTo
      createdBy
      creator {
        id
        fullName
      }

      tenant
  `;

  getAssigned$(): Observable<IDashboardListDto[]> {
    const query = `
      query() {
        dashboardGetAssigned() {
            ${DashboardDataService.getListParams}
        }
      }

      ${localizationMediumFragment()}
      ${localizationLongFragment()}
    `;

    return this.graphqlService
      .query<IDashboardUserCorrelation[]>(query)
      .pipe(
        map((values: IDashboardUserCorrelation[]) =>
          values.map((value: IDashboardUserCorrelation) => value.dashboard)
        )
      );
  }

  getAssignable$(): Observable<IDashboardPanelPreviewDto[]> {
    const query = `
      query() {
        dashboardGetAssignable() {
            ${DashboardDataService.getAssignableParams}
        }
      }

      ${localizationMediumFragment()}
      ${localizationLongFragment()}
    `;

    return this.graphqlService.query<IDashboardPanelPreviewDto[]>(query);
  }

  getById$(id: ID): Observable<IDashboardDetailDto> {
    const query = `
      {
        dashboardGetById(where: {id:{eq: ${id}}}) {
            ${DashboardDataService.getDetailParams}
        }
      }

      ${localizationMediumFragment()}
      ${localizationLongFragment()}
    `;

    return this.graphqlService.query<DashboardResponse[]>(query, { id }).pipe(
      map((values: DashboardResponse[]) => {
        const value = values[0];
        return dashboardResponseToDashboardDetailDto(value);
      })
    );
  }

  getByIdWithTranslations$(id: ID): Observable<IDashboardPanelPreviewDto> {
    const query = `
      {
        dashboardGetById(where: {id:{eq: ${id}}}) {
            ${DashboardDataService.getDetailWithoutWidgetsParams}
        }
      }

      ${localizationMediumAllFragment()}
      ${localizationLongAllFragment()}
    `;

    return this.graphqlService.query<DashboardResponse[]>(query, { id }).pipe(
      map((values: DashboardResponse[]) => {
        const value = values[0];
        return dashboardResponseToDashboard(value);
      })
    );
  }

  add$(input: IDashboardListDto): Observable<IDashboardListDto> {
    const mutation = `
      mutation ($input: DashboardAddInput!) {
        dashboardAdd(input: $input)
        {
          ${DashboardDataService.getDetailParams}
        }
      }

      ${localizationMediumFragment()}
      ${localizationLongFragment()}
    `;

    const variables = {
      input
    };

    return this.graphqlService.mutation<IDashboardListDto>(mutation, variables);
  }

  reference$(input: IDashboardReferenceDto): Observable<IDashboardListDto> {
    const mutation = `
      mutation ($input: DashboardReferenceInput!) {
        dashboardReference(input: $input)
        {
            ${DashboardDataService.getDetailParams}
        }
      }

      ${localizationMediumFragment()}
      ${localizationLongFragment()}
    `;

    const variables = {
      input
    };

    return this.graphqlService.mutation<IDashboardListDto>(mutation, variables);
  }

  update$(input: IDashboardDetailDto): Observable<IDashboardDetailDto> {
    const mutation = `
      mutation ($input: DashboardUpdateInput!) {
        dashboardUpdate(input: $input){
          ${DashboardDataService.getDetailParams}
        }
      }

      ${localizationMediumFragment()}
      ${localizationLongFragment()}
    `;

    input = {
      ...input,
      description: input.description?.localizations.find((x) => x.value)
        ? input.description
        : null
    };

    const variables = {
      input
    };

    return this.graphqlService.mutation<IDashboardDetailDto>(
      mutation,
      variables
    );
  }

  updateCorrelation$(input: IDashboardListDto): Observable<IDashboardListDto> {
    const mutation = `
      mutation ($input: DashboardCorrelationUpdateInput!) {
        dashboardUpdate(input: $input)
        {
            ${DashboardDataService.getListParams}
        }
      }
    `;

    const variables = {
      input
    };

    return this.graphqlService.mutation<IDashboardListDto>(mutation, variables);
  }

  updateAllCorrelations$(
    input: IDashboardWidgetCorrelationsUpdateInput
  ): Observable<boolean> {
    const mutation = `
      mutation ($input: DashboardWidgetCorrelationsUpdateInput!) {
        dashboardWidgetUpdateAllPositions(input: $input)
      }
    `;

    const variables = {
      input
    };

    return this.graphqlService.mutation<boolean>(mutation, variables);
  }

  delete$(id: ID): Observable<boolean> {
    const mutation = `
      mutation {
        dashboardDelete(dashboardId: ${id})
      }
    `;

    return this.graphqlService.mutation<boolean>(mutation, {});
  }

  remove$(o: IDashboardListDto): Observable<boolean> {
    const mutation = `
      mutation {
        dashboardRemove(dashboardId: ${o.id})
      }
    `;

    return this.graphqlService.mutation<boolean>(mutation, {});
  }
}
