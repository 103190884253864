<div
  [formGroup]="form"
  [patchFormGroupWithEmitValues]="config()"
  class="flex flex-col"
>
  <bo-widget-base-filter
    formControlName="filter"
    [showEnergySource]="false"
  ></bo-widget-base-filter>
</div>
