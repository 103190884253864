<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <fa-icon class="sidebar-icon" [icon]="faGridHorizontal"></fa-icon>
      {{ 'side-nav.DASHBOARD' | transloco }}

      <div class="flex flex-grow"></div>
      @if (hasDashboardAdminPermission$ | async) {
        <button mat-icon-button (click)="addDashboard($event)">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      }
      <button mat-icon-button (click)="addReference($event)">
        <fa-icon [icon]="faShare" size="lg"></fa-icon>
      </button>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ul>
    <!-- loading placeholder -->
    @if (isLoading$ | async) {
      <li>
        <cc-progress-bar [isLoading]="true"></cc-progress-bar>
      </li>
    }

    @for (dashboard of assigned(); track dashboard) {
      <li
        class="sidebar-buttom"
        [ngClass]="{
          selected: (id$ | async) === dashboard.id,
        }"
      >
        @if (id(); as userId) {
          <div class="flex flex-grow">
            <a
              class="flex flex-row w-full"
              [routerLink]="['/dashboard', dashboard.id]"
            >
              <span
                class="color"
                [ngStyle]="{ 'background-color': dashboard.color }"
              ></span>
              <span
                class="name"
                title="{{ dashboard.name.localizations[0].value }}"
                >{{ dashboard.name.localizations[0].value }}</span
              >
            </a>
            <button
              mat-icon-button
              class="menu-trigger"
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <fa-icon size="2x" [icon]="faEllipsisV"></fa-icon>
            </button>
            <mat-menu #menu="matMenu" class="dashboard-menu">
              <button
                mat-menu-item
                *ngxPermissionsOnly="permissions.admin"
                (click)="editDashboard($event, dashboard)"
                [ngClass]="{
                  disabled: dashboard.creator.id !== userId,
                }"
                title="{{
                  (dashboard.creator.id !== userId
                    ? 'DASHBOARDPANEL.DIALOG-TITLE.NOUPDATE'
                    : 'DASHBOARDPANEL.DIALOG-TITLE.UPDATE'
                  ) | transloco: { user: dashboard.creator.fullName }
                }}"
              >
                <fa-icon
                  class="sidebar-icon dashboard-icon"
                  [icon]="faEdit"
                ></fa-icon>
                <span>{{ 'common.EDIT' | transloco }}</span>
              </button>
              <button
                mat-menu-item
                (click)="removeDashboard($event, dashboard)"
                title="{{
                  'DASHBOARDPANEL.DIALOG-TITLE.REMOVE.TITLE' | transloco
                }}"
              >
                <fa-icon
                  class="sidebar-icon dashboard-icon"
                  [icon]="faEyeSlash"
                ></fa-icon>
                <span>{{ 'common.HIDE' | transloco }}</span>
              </button>
              <button
                mat-menu-item
                *ngxPermissionsOnly="permissions.admin"
                (click)="deleteDashboard($event, dashboard)"
                [ngClass]="{
                  disabled: dashboard.creator.id !== userId,
                }"
                title="{{
                  (dashboard.creator.id !== userId
                    ? 'DASHBOARDPANEL.DIALOG-TITLE.NODELETE'
                    : 'DASHBOARDPANEL.DIALOG-TITLE.DELETE.TITLE'
                  ) | transloco: { user: dashboard.creator.fullName }
                }}"
              >
                <fa-icon
                  class="sidebar-icon dashboard-icon"
                  [icon]="faTrashAlt"
                ></fa-icon>
                <span>{{ 'common.DELETE' | transloco }}</span>
              </button>
            </mat-menu>
          </div>
        }
      </li>
    }

    @if (assigned().length === 0) {
      <li class="empty">
        <a>
          <span class="name">{{ 'common.EMPTY' | transloco }}</span>
        </a>
      </li>
    }
  </ul>
</mat-expansion-panel>
