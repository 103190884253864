import { inject } from '@angular/core';
import { WidgetTypeProvider } from '@clean-code/shared/components/ui-dashboard';
import { ChartConfigComponent } from './chart-config/chart-config.component';
import { ChartWidgetComponent } from './chart-widget/chart-widget.component';

enum WidgetTypes {
  TimeSeriesChartWidgetType = 'TimeSeriesComparisonChartWidget',
}

export function addTimeSeriesIdentifierComparisonChartWidget() {
  const widgetTypes = inject(WidgetTypeProvider);
  widgetTypes.add(
    WidgetTypes.TimeSeriesChartWidgetType,
    ChartConfigComponent,
    ChartWidgetComponent,
    ['backoffice', 'frontoffice', 'frontoffice-int']
  );
}
