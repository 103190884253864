
import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { WidgetBaseFilterComponent } from '@backoffice-frontend/shared/bo/widgets/ui-widget-base-filter';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { PatchFormGroupValuesDirective } from '@clean-code/shared/directives/ui-form';
import { MarketSegmentsContractVolumeWidgetTypeConfig } from './../market-segments-contract-volume';

@Component({
  standalone: true,
  selector: 'dashboard-market-hkn.customer-segments-config',
  templateUrl: './market-segments-contract-volume-config.component.html',
  styleUrls: ['./market-segments-contract-volume-config.component.css'],
  imports: [
    ReactiveFormsModule,
    PatchFormGroupValuesDirective,
    WidgetBaseFilterComponent
],
})
export class MarketSegmentsContractVolumeConfigComponent extends BaseWidgetTypeConfigComponent<MarketSegmentsContractVolumeWidgetTypeConfig> {
  override form = new UntypedFormGroup({
    filter: new UntypedFormControl(null, [Validators.required]),
  });

  public close() {
    // TODO: dashboard state
    // this.callback.next();
  }
}
