<cc-progress-bar [isLoading]="isLoading$ | async"></cc-progress-bar>

<div mat-dialog-title class="!flex">
  <h2 class="dialog-title">{{ this.title }}</h2>

  <fa-icon
    class="cursor-pointer"
    [icon]="faXmarkLarge"
    mat-dialog-close
  ></fa-icon>
</div>
<mat-dialog-content>
  <cc-time-span-toggle [timeFrame]="selectedTimeFrame$"></cc-time-span-toggle>
  <cc-line-chart [data]="data$ | async" [legend]="legend"></cc-line-chart>
</mat-dialog-content>
