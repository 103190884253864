<div class="dashboard-edit-dialog-component">
  <h2 mat-dialog-title class="dialog-title">
    {{ 'DASHBOARDPANEL.DIALOG-TITLE.UPDATE' | transloco }}
  </h2>

  <div mat-dialog-content>
    <dashboard-dashboard-edit
      #editChild
      [dashboard]="dashboard"
    ></dashboard-dashboard-edit>
  </div>

  <div mat-dialog-actions class="flex flex-row justify-end">
    <cc-cancel-action-button (onClick)="close()"></cc-cancel-action-button>
    <cc-save-action-button (onClick)="save()"></cc-save-action-button>
  </div>
</div>
