
import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';
import { WidgetTypeConfig } from '@backoffice-frontend/time-series-identifier/domain';
import { TimeSeriesDynamicSelectionGroupComponent } from '@backoffice-frontend/time-series-identifier/ui-time-series-dynamic-selection';
import { BaseWidgetTypeConfigComponent } from '@clean-code/shared/dashboard/widget/api-widget';
import { PatchFormGroupWithEmitValuesDirective } from '@clean-code/shared/directives/ui-form';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  standalone: true,
  selector: 'app-table-widget-config',
  templateUrl: './table-widget-config.component.html',
  styleUrls: ['./table-widget-config.component.css'],
  imports: [
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    TranslocoModule,
    VarDirective,
    PatchFormGroupWithEmitValuesDirective,
    TimeSeriesDynamicSelectionGroupComponent
],
})
export class TableWidgetConfigComponent extends BaseWidgetTypeConfigComponent<WidgetTypeConfig> {
  public override form = new UntypedFormGroup({
    dataSources: new UntypedFormArray([]),
    settings: new UntypedFormGroup({
      deltaType: new UntypedFormControl('DAY', [Validators.required]),
      hideClosingValueHeader: new UntypedFormControl(false, [
        Validators.required,
      ]),
    }),
  });

  public close() {
    // this.callback.next();
  }

  public get dataSources(): UntypedFormArray {
    return this.form.get('dataSources') as UntypedFormArray;
  }

  // public override ngAfterViewInit(): void {
  //   if (this.form) {
  //     this.form.valueChanges
  //       .pipe(
  //         debounceTime(200),
  //         distinctUntilChanged(), //  execute only if a different value is emitted
  //         filter((newData: WidgetTypeConfig) => !!newData.dataSources),
  //         tap((newData: WidgetTypeConfig) => {
  //           newData.dataSources.forEach(
  //             (dataSource: DataSource, index: number) => {
  //               if (!dataSource.settings.identifier) {
  //                 return;
  //               }

  //               const oldSortOrder = dataSource.settings.identifier.sortOrder;
  //               dataSource.settings.identifier.sortOrder = index;

  //               if (!this.config) {
  //                 this.config = {};
  //                 this.config.dataSources = [dataSource];
  //                 return;
  //               }

  //               const oldSettings = this.config.dataSources.find(
  //                 (oldDataSource: DataSource) =>
  //                   oldDataSource.settings.identifier.sortOrder === oldSortOrder
  //               );
  //               if (oldSettings) {
  //                 dataSource.settings = {
  //                   ...oldSettings.settings,
  //                   ...dataSource.settings,
  //                 };
  //               }
  //             }
  //           );

  //           this.config.settings = this.form.get('settings').value;
  //           this.callback.next(this.config);
  //         }),
  //         takeUntil(this.closeSubject)
  //       )
  //       .subscribe();
  //   }
  // }
}
