<div
  *ngVar="config(); let config"
  [formGroup]="form"
  [patchFormGroupWithEmitValues]="config"
>
  <div formGroupName="settings">
    <mat-form-field>
      <mat-label>{{
        'time-series-identifier.TIME_FRAME' | transloco
      }}</mat-label>
      <mat-select formControlName="timeFrame">
        <!-- TODO: find a solution on server to deserialize -->
        @for (item of timeFrames$ | async; track item) {
          <mat-option value="{{ item.name }}">{{
            'time-span-toggle.' + item.name | transloco
          }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <time-series-dynamic-selection-group
    [formGroup]="form"
    [dataSource]="config?.dataSources"
  ></time-series-dynamic-selection-group>
</div>
