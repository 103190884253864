@if (!mobile) {
  <div>
    <div
      class="group flex items-center gap-3 py-2 justify-between font-bold text-layout-sidenav-text dark:text-layout-dark-sidenav-text"
    >
      <button
        type="button"
        class="flex flex-row space-x-1 items-center cursor-pointer"
        aria-expanded="false"
        aria-haspopup="true"
        (click)="toggleUserInfo()"
      >
        {{ fullName() }}
        <!-- <fa-icon
        [icon]="faUser"
        size="2x"
        class="text-sm fa-fw w-8 hover:text-primary-color"
        [ngClass]="showUserInfo ? 'text-primary-color' : ''"
      ></fa-icon> -->
        <fa-icon
          [icon]="faChevronDown"
          size="xs"
          class="mx-3"
          [class]="'fa-rotate-' + (showUserInfo ? '180' : 0)"
          [ngClass]="showUserInfo ? 'text-primary-color' : 'text-gray-400'"
        ></fa-icon>
        <!-- <div class="font-medium text-sm text-ellipsis">
      {{ fullName$ | async }}
    </div> -->
      </button>
      <notifications-notification-center
        class="cursor-pointer text-layout-sidenav-text"
      ></notifications-notification-center>
    </div>
    @if (showUserInfo) {
      <div
        class="flex flex-col mx-5 mb-5 text-gray-600 dark:text-layout-dark-page-container-text font-normal underline text-sm"
        (click)="toggleUserInfo()"
      >
        <ul class="list-disc">
          <!-- <li class="mb-3">
      <a
        [routerLink]="['/', 'settings', 'users', 'preview', userId$ | async]"
        >
        {{ 'user.MANAGE' | transloco }}
      </a>
    </li> -->
          @if (false) {
            <li class="mb-3">
              <a routerLink="/limits">
                {{ 'fof.USERLIMITS' | transloco }}
              </a>
            </li>
          }
          @if (showKam()) {
            <li class="mb-3">
              <a routerLink="/kam">
                {{ 'fof.KAM' | transloco }}
              </a>
            </li>
          }
          @if (false) {
            <li class="mb-3">
              <a routerLink="/team">
                {{ 'fof.TEAM' | transloco }}
              </a>
            </li>
          }
          <li>
            <button (click)="logOut()">
              {{ 'login.LOGOUT' | transloco }}
            </button>
          </li>
        </ul>
      </div>
    }
    <!-- <div
class="group flex items-center pr-2 py-2 rounded-md text-layout-text-navigation hover:text-gray-900 hover:bg-primary-color-50 hover:cursor-pointer"
>
<notifications-notification-center></notifications-notification-center>
</div> -->
  </div>
}

@if (mobile) {
  <div
    class="flex flex-row lg:mx-2 lg:my-3 text-layout-sidenav-text dark:text-layout-dark-sidenav-text"
  >
    <notifications-notification-center></notifications-notification-center>
    <button
      mat-button
      [matMenuTriggerFor]="menu"
      type="button"
      class="max-w-xs bg-layout-page-container dark:bg-layout-dark-page-container flex items-center text-sm"
      id="user-menu-button"
      aria-expanded="false"
      aria-haspopup="true"
    >
      <span class="sr-only">Open user menu</span>
      <fa-icon
        class="text-layout-sidenav-text"
        [icon]="faUser"
        size="2x"
      ></fa-icon>
    </button>
  </div>
}

<mat-menu #menu="matMenu" xPosition="before">
  <div
    class="w-48 origin-top-right divide-y focus:outline-none text-layout-sidenav-text"
  >
    <div class="py-1" role="none">
      <div class="text-center">{{ fullName() }}</div>
    </div>
  </div>
  <div class="card">
    <div class="py-1" role="none">
      <!-- <div
      class="px-3 py-1 pb-2 text-sm text-gray-500 hover:text-primary-color"
      >
      <a
        [routerLink]="['/', 'settings', 'users', 'preview', userId$ | async]"
        >
        {{ 'user.MANAGE' | transloco }}
      </a>
    </div> -->

      @if (false) {
        <div class="px-3 py-1 text-sm text-gray-500 hover:text-primary-color">
          <a routerLink="/limits">
            {{ 'fof.USERLIMITS' | transloco }}
          </a>
        </div>
      }

      @if (false) {
        <div class="relative m-2">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-start">
            <span class="pr-2 bg-secondary-color text-sm text-gray-500">
              {{ 'fof.COUNTERPART' | transloco }}
            </span>
          </div>
        </div>
      }

      @if (showKam()) {
        <div class="px-3 pb-1 text-sm text-gray-500 hover:text-primary-color">
          <a routerLink="/kam">
            {{ 'fof.KAM' | transloco }}
          </a>
        </div>
      }

      @if (false) {
        <div class="px-3 pb-1 text-sm text-gray-500 hover:text-primary-color">
          <a routerLink="/team">
            {{ 'fof.TEAM' | transloco }}
          </a>
        </div>
      }

      <div class="text-center text-layout-sidenav-text border-t pt-2">
        <button mat-button (click)="logOut()">
          {{ 'login.LOGOUT' | transloco }}
        </button>
      </div>
    </div>
  </div>
</mat-menu>
