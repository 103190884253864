<h2 mat-dialog-title>{{ 'dashboard.reference.dialog.title' | transloco }}</h2>

<mat-dialog-content>
  <dashboard-dashboard-reference
    (callback)="onSave()"
    [showFooter]="false"
  ></dashboard-dashboard-reference>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <cc-cancel-action-button mat-dialog-close></cc-cancel-action-button>
</mat-dialog-actions>
