import { NgModule, inject } from '@angular/core';
import { BoNotificationsModule } from '@backoffice-frontend/shared/bo/util-bo-notifications';
import { TailwindThemeService } from '@backoffice-frontend/shared/ui-tailwind';
// import { TailwindThemeModule } from '@backoffice-frontend/shared/ui-tailwind';
import { Scripts } from '@clean-code/shared/common';
import tailwindThemeConfig from '../assets/config/tailwind-theme.config.json';
// import { ConfigModule } from '@clean-code/shared/util-config';
import { DashboardModule } from './modules/dashboard/dasboard.module';
import { FormulaModule } from './modules/formula/formula.module';

const ScriptStore: Scripts[] = [
  {
    name: 'html2canvas',
    src: '../assets/scripts/html2canvas/html2canvas.min.js',
  },
  { name: 'pdfmake', src: '../assets/scripts/pdfmake/pdfmake.min.js' },
  {
    name: 'katex',
    src: '../assets/scripts/katex/katex@0.16.8.min.js',
  },
];
@NgModule({
  exports: [],
  declarations: [],
  imports: [DashboardModule, FormulaModule, BoNotificationsModule],
  providers: [],
})
export class AppModule {
  constructor() {
    // _accountService: AccountService // TODO: for service injection, provide inRoot does not work by refresh // _dateAdapter: DateAdapter<Dayjs>, // _trace: Sentry.TraceService, // _updateService: UpdateService // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // Localize.Init(dateAdapter);
    const tailwindService = inject(TailwindThemeService);

    tailwindService.loadConfig(tailwindThemeConfig);

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          // unregister service worker
          console.log('serviceWorker unregistered');
          registration.unregister();
        }
      });
    }
  }
}
