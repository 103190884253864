<div
  *ngVar="config(); let config"
  [formGroup]="form"
  [patchFormGroupWithEmitValues]="config"
>
  <div formGroupName="settings" class="flex flex-row space-x-1 items-center">
    <mat-form-field>
      <mat-label>{{
        'time-series-identifier.TIME_FRAME' | transloco
      }}</mat-label>
      <mat-select formControlName="timeFrame">
        <mat-option [value]="null" selected>{{
          'common.NO_SELECTION' | transloco
        }}</mat-option>
        @for (item of timeFrames$ | async; track item) {
          <mat-option value="{{ item.name }}">{{
            'time-span-toggle.' + item.name | transloco
          }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        formControlName="startDate"
        [matDatepicker]="picker"
        [placeholder]="'time-series-identifier.INDEX_START_DATE' | transloco"
      />

      <mat-datepicker-toggle matSuffix (click)="clearStartDate($event)">
        <fa-icon matDatepickerToggleIcon [icon]="faTimes"></fa-icon>
      </mat-datepicker-toggle>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-checkbox formControlName="showInPercentage">{{
      'time-series-identifier.comparison-widget.SHOW_IN_PERCENTAGE' | transloco
    }}</mat-checkbox>
  </div>
  @if (form.hasError('atLeastOne')) {
    <div style="color: red">
      {{ 'ui-spread-widget.TIME_FRAME_NOT_VALID' | transloco }}
    </div>
  }
  <time-series-dynamic-selection-group
    [formGroup]="form"
    [dataSource]="config?.dataSources"
  ></time-series-dynamic-selection-group>
  <!-- !TODO: update to v18 -->
  <!-- [calculationType]="calculationType$ | async" -->
</div>
