import { Route } from '@angular/router';
import { ForecastResolver } from '@backoffice/forecast/domain';
import { EnergySourceResolver } from '@clean-code/shared/bo/util-common';
import {
  ForecastImportedGuard,
  ForecastNotImportedGuard,
} from './forecast-imported.guard';
export const forecastRoute: Route = {
  path: 'forecast/:id',
  loadComponent: () =>
    import('./forecast/forecast.component').then(
      (mod) => mod.ForecastComponent
    ),
  runGuardsAndResolvers: 'always',
  resolve: {
    entity: ForecastResolver,
    energySource: EnergySourceResolver,
  },
  children: [
    {
      path: '',
      canActivate: [ForecastNotImportedGuard],

      loadComponent: () =>
        import('./forecast-form/forecast-form.component').then(
          (mod) => mod.ForecastFormComponent
        ),

      data: {
        lable: 'forecast.CONFIGURATION',
        translate: 'forecast',
      },
    },
    {
      path: 'edit',
      canActivate: [ForecastNotImportedGuard],

      loadComponent: () =>
        import('./forecast-form/forecast-form.component').then(
          (mod) => mod.ForecastFormComponent
        ),

      data: {
        lable: 'forecast.CONFIGURATION',
        translate: 'forecast',
      },
    },
    {
      path: ':source/:sourceId',
      loadComponent: () =>
        import('./forecast-form/forecast-form.component').then(
          (mod) => mod.ForecastFormComponent
        ),
      data: { translate: 'forecast' },
    },
    {
      path: ':source/:sourceId/:energySource',
      loadComponent: () =>
        import('./forecast-form/forecast-form.component').then(
          (mod) => mod.ForecastFormComponent
        ),
      data: { translate: 'forecast' },
    },
    {
      path: ':source/:sourceId/:energySource',
      loadComponent: () =>
        import('./forecast-form/forecast-form.component').then(
          (mod) => mod.ForecastFormComponent
        ),
      data: { translate: 'forecast' },
    },
    {
      path: 'imported',
      canActivate: [ForecastImportedGuard],
      loadComponent: () =>
        import(
          './forecast-imported-form/forecast-imported-form.component'
        ).then((mod) => mod.ForecastImportFormComponent),

      data: {
        translate: 'forecast',
      },
    },
    {
      path: 'scale',
      loadComponent: () =>
        import('backoffice/forecast/forecast-scales-edit-form').then(
          (mod) => mod.ForecastEditorComponent
        ),

      data: {
        translate: 'forecast',
      },
    },
  ],
};
