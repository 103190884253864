<div class="flex flex-row items-center w-full">
  <div class="icon spacer" matTooltip="{{ buySignal.energySource }}">
    @switch (buySignal.energySource) {
      @case ('Gas') {
        <fa-icon [icon]="faBurn" class="gas-icon"></fa-icon>
      }
      @case ('Power') {
        <fa-icon [icon]="faPlug" class="power-icon"></fa-icon>
      }
      @case ('Other') {
        <fa-icon [icon]="faBorderAll" class="other-icon"></fa-icon>
      }
      @default {
        <fa-icon [icon]="faExclamationTriangle"></fa-icon>
      }
    }
  </div>
  <div class="flex flex-col overflow-hidden w-full">
    <div class="text-primary-color title">
      {{
        'limit-reports.notification.limitreportbuysignal.BUYSIGNAL' | transloco
      }}
    </div>

    <div class="truncate">
      <span class="italic"
        >{{
          'limit-reports.notification.limitreportbuysignal.CUSTOMER'
            | transloco
        }}:
      </span>
      <span>{{ buySignal.customer.caption }}</span>
    </div>
    <div class="truncate">
      <span class="italic"
        >{{
          'limit-reports.notification.limitreportbuysignal.LIMITREPORT'
            | transloco
        }}:
      </span>
      <span>
        {{ buySignal.limitReport.caption }}
      </span>
    </div>
    <div>
      <span class="italic">
        {{
          'limit-reports.notification.limitreportbuysignal.PRODUCT' | transloco
        }}: </span
      ><span>{{ buySignal.product.caption }}</span>
    </div>
    <div class="flex flex-row">
      <div class="flex-grow">
        <span class="italic">
          {{
            'limit-reports.notification.limitreportbuysignal.STRATEGY'
              | transloco
          }}:
        </span>
        <span>{{ buySignal.strategy }}</span>
      </div>
      <div>
        <span class="italic">
          {{
            'limit-reports.notification.limitreportbuysignal.SIGNALDATE'
              | transloco
          }}:
        </span>
        <span>{{ buySignal.signalDate | date: 'mediumDate' }}</span>
      </div>
    </div>
  </div>
</div>
