import { Route } from '@angular/router';

export const formulaBuilderRoutes: Route = {
  path: 'formulas',
  loadComponent: () =>
    import('@clean-code/feature/formula-builder/formula-builder-list').then(
      (mod) => mod.FormulaBuilderListComponent
    ),
  children: [
    {
      path: 'preview/:id',
      loadComponent: () =>
        import('@clean-code/feature/formula-builder/formula-builder-list').then(
          (mod) => mod.FormulaBuilderFormComponent
        ),
    },
  ],
};
