import {
  LoadProfileDetailsResolver,
  LoadProfileResolver,
} from '@backoffice/load-profile/domain';

export const loadProfileDetailsRoute = {
  path: 'load-profile-details/:id',
  loadComponent: () =>
    import('./load-profile-details.component').then(
      (mod) => mod.LoadProfileDetailsComponent
    ),
  data: {
    translate: 'load-profile',
  },
  resolve: {
    entity: LoadProfileResolver,
    years: LoadProfileDetailsResolver,
  },
};
