<ng-container [formGroup]="formGroup">
  <mat-form-field>
    <input
      matInput
      [matDatepicker]="startDate"
      formControlName="startDate"
      placeholder="{{ 'areas.trading-calendar.fields.DATE' | transloco }}"
    />
    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker #startDate></mat-datepicker>
  </mat-form-field>
  <div class="flex flex-row space-x-2">
    <mat-form-field [appearance]="null">
      <mat-label>{{ 'ui-formula-builder.METHOD' | transloco }}</mat-label>
      <mat-select formControlName="timeFrameType">
        <mat-option [value]="null" selected>{{
          'common.NO_SELECTION' | transloco
        }}</mat-option>
        @for (item of timeFrameTypes$ | async; track item) {
          <mat-option [value]="item.name">
            {{ item.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'common.VALUE' | transloco }}</mat-label>
      <input matInput formControlName="value" />
    </mat-form-field>
  </div>
</ng-container>
