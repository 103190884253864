
import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlChild } from '@clean-code/shared/components/ui-form-controls';
import { NgxColorsModule } from 'ngx-colors';

@Component({
  standalone: true,
  selector: 'cc-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [FormsModule, NgxColorsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent extends BaseFormControlChild {
  private colorInternal: string;

  public colorPalette: Array<string> = [
    '#CDCDCD',
    '#E6DF44',
    '#FFA000',
    '#B2912F',
    '#EC5800',

    '#F17CB0',
    '#CF3569',
    '#B276B2',
    '#9591A6',
    '#76A7C3',

    '#0F5D86',
    '#0AB3E3',
    '#ACDCD2',
    '#5DC3B3',
    '#8FCFA9',
  ];

  override onChange = (_value: string) => '';

  @Input()
  public set color(val: string) {
    this.colorInternal = val || '#cdcdcd';
    this.onChange(this.colorInternal);
  }

  public get color(): string {
    return this.colorInternal;
  }

  override get value(): string {
    return this.color;
  }

  override set value(value: string) {
    this.color = value;
    this.onChange(this.color);
  }

  public constructor() {
    super();
  }

  override writeValue(value: string) {
    this.color = value;
  }

  override registerOnChange(fn: any) {
    this.onChange = fn;
  }
}
